import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useClassGroupFetcher } from './use-class-group-fetcher'

const ClassGroupSelect = ({ disciplineId, ...antdProps }) => {
  const { classGroups } = useClassGroupFetcher({ disciplineId })

  return (
    <Select
      {...antdProps}
      showSearch
      emptyText='Nenhum assunto encontrado'
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      allowClear
    >
      {classGroups.map(classGroup => (
        <Option key={classGroup.id} value={classGroup.id}>
          {classGroup.title}
        </Option>
      ))}
    </Select>
  )
}

ClassGroupSelect.propTypes = {
  disciplineId: t.string,
}

export { ClassGroupSelect }
