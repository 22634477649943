import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { Spinner } from 'ui'
import { useAuth } from 'resources'
import { PARTNERS_COUPONS_REPORT, NEW_PASSWORD, HOME, LOGIN, RECOVER_PASSWORD } from 'routes'
import * as Sentry from '@sentry/react'

const MainPage = lazy(() => import('pages/main'))
const Login = lazy(() => import('pages/login'))
const RecoverPassword = lazy(() => import('pages/recover-password'))
const NewPassword = lazy(() => import('pages/new-password'))
const PartnerCouponsReport = lazy(() =>
  import('pages/partner-coupons-report/partner-coupons-report')
)

const PUBLIC_PAGES = [LOGIN, RECOVER_PASSWORD, NEW_PASSWORD, PARTNERS_COUPONS_REPORT]

function App () {
  const { pathname } = useLocation()
  const { didCheckUserIn, userInfo: { isUserLoggedIn } } = useAuth()

  if (!didCheckUserIn) {
    return <Spinner />
  }

  if (isUserLoggedIn && pathname === LOGIN) {
    return <Redirect to={HOME} />
  }

  if (!isUserLoggedIn && !PUBLIC_PAGES.includes(pathname)) {
    return <Redirect to={LOGIN} />
  }

  return (
    <Sentry.ErrorBoundary fallback='An error has occurred'>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path={LOGIN} children={<Login />} />
          <Route path={RECOVER_PASSWORD} children={<RecoverPassword />} />
          <Route path={NEW_PASSWORD} children={<NewPassword />} />
          <Route path={PARTNERS_COUPONS_REPORT} children={<PartnerCouponsReport />} />
          <MainPage />
        </Switch>
      </Suspense>
    </Sentry.ErrorBoundary>
  )
}

export default App
