import { useQuery, gql } from '@apollo/client'
import { getNodes } from 'resources'

const useAudiencesFetcher = () => {
  const { data, loading } = useQuery(AUDIENCES_QUERY)

  return {
    audiences: getNodes('audiences', data),
    loading,
  }
}

const AUDIENCES_QUERY = gql`
  {
    audiences{
      nodes {
        id
        title
      }
    }
  }
`

export { useAudiencesFetcher }
