import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'

import defaultLogo from 'assets/logo.svg'
import whiteLogo from 'assets/logo-white.svg'

const Logo = ({ size = 250, variant = 'default' }) => (
  <Image
    size={size}
    src={variants[variant]}
    alt='Ferretto CMS Logo'
  />
)

const variants = {
  default: defaultLogo,
  white: whiteLogo,
}

const Image = styled.img`
  width: ${props => props.size}px;
`

Logo.propTypes = {
  size: t.number,
  variant: t.oneOf(['default', 'white']),
}

export { Logo }
