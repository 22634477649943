import React from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { getColorVariant } from 'resources'

const BurgerButton = ({ onClick }) => (
  <Button onClick={onClick}>
    <Bar width={24} />
    <Bar width={24} />
    <Bar width={12} />
  </Button>
)

BurgerButton.propTypes = {
  onClick: t.func.isRequired,
}

const Bar = styled.span`
  width: ${props => props.width}px;
  display: block;
  background: ${getColorVariant('primary', 'main')};
  height: 3px;
  margin: 4px 0;
  transition: 200ms;
`

const Button = styled.button`
  border: 0;
  background: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  &:hover{
    > span {
      width: 24px;
    }
  }
`

export { BurgerButton }
