const theme = {
  fontFamily: "'Montserrat', sans-serif",
  colors: {
    background: '#F5F5FB',
    black: '#07081d',
    gray: {
      light: '#D0D0D0',
      main: '#adadad',
      dark: '',
    },
    primary: {
      light: '',
      main: '#0049FF',
      dark: '',
    },
    success: {
      main: '#6DD400',
    },
    warning: {
      main: '#F7B500',
    },
    danger: {
      main: '#FF3F2F',
    },
  },
  spacing: [
    0,
    6,
    12,
    24,
    36,
    48,
    60,
    72,
  ],
  sidebar: {
    width: 280,
  },
  header: {
    height: 64,
  },
}

const antdTheme = {
  '@primary-color': theme.colors.primary.main,
  '@font-family': theme.fontFamily,
  '@background-color': theme.colors.background,
}

module.exports = {
  antdTheme,
  theme,
}
