import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { Drawer as AntdDrawer, Typography } from 'antd'
import { Footer } from './footer'
import { media } from 'resources'

const FormDrawer = ({
  children,
  loading,
  closable = true,
  placement = 'right',
  visible,
  onCancel,
  onClose,
  onConfirm,
  title,
  width = 400,
}) => (
  <Drawer
    closable={closable}
    placement={placement}
    onClose={onClose}
    open={visible}
    size={width}
    footerStyle={{ padding: 24 }}
    title={<Typography.Title level={3}>{title}</Typography.Title>}
    footer={
      <Footer onCancel={onCancel} onConfirm={onConfirm} loading={loading} />
    }
  >
    {children}
  </Drawer>
)

const Drawer = styled(AntdDrawer)`
  ${media.greaterThan('tablet')`
    >.ant-drawer-content-wrapper{
      min-width: ${(props) => props.size}px;
    }
  `}

  ${media.lessThan('tablet')`
    >.ant-drawer-content-wrapper{
      min-width: 100vw;
    }
  `}
`

export { Drawer }

FormDrawer.propTypes = {
  children: t.node.isRequired,
  loading: t.bool,
  closable: t.bool,
  placement: t.string,
  visible: t.bool.isRequired,
  onCancel: t.func.isRequired,
  onClose: t.func.isRequired,
  onConfirm: t.func.isRequired,
  title: t.string.isRequired,
  width: t.number,
}

export { FormDrawer }
