import React, { useCallback, useState, useContext, useEffect } from 'react'
import t from 'prop-types'
import { useMutation } from '@apollo/client'

import { setToken, removeToken, getToken } from './store'
import { LOGIN_MUTATION } from './login-mutation'

const AuthContext = React.createContext()

function AuthProvider ({ children }) {
  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION)
  const [didCheckUserIn, setDidCheckUserIn] = useState(false)
  const [userInfo, setUserInfo] = useState({
    isUserLoggedIn: false,
    user: null,
  })

  const checkUser = useCallback(async () => {
    try {
      const { token } = await getToken()

      setUserInfo({ isUserLoggedIn: !!token, user: null })
    } catch (_) {
      setUserInfo({ isUserLoggedIn: false, user: null })
    }

    setDidCheckUserIn(true)
  }, [setUserInfo, setDidCheckUserIn])

  const login = useCallback(async loginData => {
    const input = {
      ...loginData,
      origin: 'ADMIN',
    }
    const { data } = await loginMutation({ variables: { input } })

    await setToken(data.login)

    setUserInfo({
      isUserLoggedIn: true,
      user: {},
    })
  }, [loginMutation])

  const logout = useCallback(async () => {
    await removeToken()
    setUserInfo({
      isUserLoggedIn: false,
      user: null,
    })
  }, [setUserInfo])

  useEffect(() => {
    checkUser()
  }, [checkUser, logout])

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        userInfo,
        isLogging: loading,
        didCheckUserIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: t.node.isRequired,
}

function useAuth () {
  return useContext(AuthContext)
}

export { AuthProvider, AuthContext, useAuth }
