import React from 'react'
import t from 'prop-types'
import { Link } from 'react-router-dom'

import { Item, Label } from './components'

const MenuItem = ({ link, label, icon: Icon, ...props }) => (
  <Item key={link} {...props}>
    <Link to={link}>
      {Icon && <Icon />}
      <Label>{label}</Label>
    </Link>
  </Item>
)

MenuItem.propTypes = {
  link: t.string.isRequired,
  label: t.string.isRequired,
  icon: t.object,
}

export { MenuItem }
