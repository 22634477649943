import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

import { useStudyListFetcher } from './use-study-list-fetcher'

const StudyListSelect = ({ scheduleType, ...antdProps }) => {
  const { studyLists } = useStudyListFetcher(scheduleType)

  return (
    <Select {...antdProps}>
      {studyLists.map(studyList => (
        <Option key={studyList.id} value={studyList.id}>
          {studyList.name}
        </Option>
      ))}
    </Select>
  )
}

StudyListSelect.propTypes = {
  scheduleType: t.string,
}

export { StudyListSelect }
