import { useCallback, useMemo } from 'react'
import { notification } from 'antd'
import { mergeRight } from 'ramda'

export const Errors = {
  GRAPHQL_ERROR: 'GRAPHQL_ERROR',
  NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
  SERVER_ERROR: 'SERVER_ERROR',
  I_DONT_KNOW_WHAT_THAT_ERROR_MEANS: 'I_DONT_KNOW_WHAT_THAT_ERROR_MEANS',
  MAIN_VIDEO_ALREADY_DEFINED: 'MAIN_VIDEO_ALREADY_DEFINED',
  PARENT_SUBJECT_ALREADY_EXISTS: 'PARENT_SUBJECT_ALREADY_EXISTS',
  ENTITY_ALREADY_EXISTS: 'ENTITY_ALREADY_EXISTS',
  DUPLICATE_DISCIPLINE: 'DUPLICATE_DISCIPLINE',
  EMPTY_CLASS_GROUP: 'EMPTY_CLASS_GROUP',
  QUESTION_VIDEO_ALREADY_DEFINED: 'QUESTION_VIDEO_ALREADY_DEFINED',
}

export const Messages = {
  UNEXPECTED_ERROR: 'Ocorreu um erro inesperado, por favor tente novamente em alguns minutos.',
  NOT_AUTHENTICATED: 'Você não está logado na plataforma. Faça login para continuar',
  MAIN_VIDEO_ALREADY_DEFINED: 'Já existe uma aula cadastrada, onde este vídeo foi definido como principal.',
  PARENT_SUBJECT_ALREADY_EXISTS: 'Já existe um assunto primário com esse nome.',
  ENTITY_ALREADY_EXISTS: 'Já existe este registro na base de dados.',
  DUPLICATE_DISCIPLINE: 'Disciplina já utilizada',
  EMPTY_CLASS_GROUP: 'Não é permitido grupo de aula sem itens',
  QUESTION_VIDEO_ALREADY_DEFINED: 'Já existe uma questão cadastrada com esse vídeo',
}

export const useError = ({ messages: customMessages } = {}) => {
  const messages = useMemo(() => mergeMessages(customMessages), [customMessages])

  const showError = useCallback(description => notification.open({
    type: 'error',
    message: 'Algo deu errado',
    description,
  }), [])

  const dispatchError = useCallback(({ networkError, graphQLErrors }) => {
    if (hasError(networkError)) {
      const errors = networkError.result.errors
      errors.forEach(error => {
        const message = getError(messages, error.code)
        showError(message)
      })
      return
    }

    if (graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.forEach(error => {
        const message = getError(messages, error.code)
        showError(message)
      })
      return
    }
    showError(Messages.UNEXPECTED_ERROR)
  }, [messages, showError])

  return [dispatchError]
}

const hasError = networkError => networkError &&
  networkError.result &&
  networkError.result.errors &&
  networkError.result.errors.length > 0

const mappedErrors = {
  [Errors.GRAPHQL_ERROR]: Messages.UNEXPECTED_ERROR,
  [Errors.SERVER_ERROR]: Messages.UNEXPECTED_ERROR,
  [Errors.I_DONT_KNOW_WHAT_THAT_ERROR_MEANS]: Messages.UNEXPECTED_ERROR,
  [Errors.NOT_AUTHENTICATED]: Messages.NOT_AUTHENTICATED,
  [Errors.MAIN_VIDEO_ALREADY_DEFINED]: Messages.MAIN_VIDEO_ALREADY_DEFINED,
  [Errors.PARENT_SUBJECT_ALREADY_EXISTS]: Messages.PARENT_SUBJECT_ALREADY_EXISTS,
  [Errors.ENTITY_ALREADY_EXISTS]: Messages.ENTITY_ALREADY_EXISTS,
  [Errors.DUPLICATE_DISCIPLINE]: Messages.DUPLICATE_DISCIPLINE,
  [Errors.EMPTY_CLASS_GROUP]: Messages.EMPTY_CLASS_GROUP,
  [Errors.QUESTION_VIDEO_ALREADY_DEFINED]: Messages.QUESTION_VIDEO_ALREADY_DEFINED,
}

const mergeMessages = (messages = {}) => mergeRight(mappedErrors, messages)
const getError = (messages, code) => messages[code] || Errors.UNEXPECTED_ERROR
