import { gql, useQuery } from '@apollo/client'
import { pipe, defaultTo, prop } from 'ramda'

const ME_QUERY = gql`
  query User {
    me {
      name
      photo
    }
  }
`

const useMe = () => {
  const { data, loading } = useQuery(ME_QUERY, {
    fetchPolicy: 'no-cache',
  })

  return {
    user: getNode(data),
    loading,
  }
}

const getNode = pipe(
  prop('me'),
  defaultTo({})
)

export { useMe }
