import React from 'react'
import t from 'prop-types'
import { Row, Col, Button } from 'antd'

const Footer = ({ onCancel, onConfirm, loading }) => (
  <Row gutter={[12, 0]}>
    <Col span={12}>
      <Button
        onClick={onCancel}
        size='large'
        block
      >
        Cancelar
      </Button>
    </Col>

    <Col span={12}>
      <Button
        loading={loading}
        size='large'
        type='primary'
        onClick={onConfirm}
        block
      >
        Confirmar
      </Button>
    </Col>
  </Row>
)

Footer.propTypes = {
  onCancel: t.func.isRequired,
  onConfirm: t.func.isRequired,
  loading: t.bool,
}

export { Footer }
