import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { Tabs as AntdTabs } from 'antd'

const CardTabs = ({ type = 'card', ...props }) => (
  <CustomTabs type={type} {...props} />
)

const CustomTabs = styled(AntdTabs)`
  &&& {
    .ant-tabs-tab {
      padding: 0.75rem 2.5rem;
      border: 0;
      overflow: auto !important;
    }
    .ant-tabs-tab-active.ant-tabs-tab,
    .ant-tabs-card-content,
    .ant-tabs-content-holder {
      background: #fff;
      overflow: auto !important;
    }

    > .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
      background: #fff !important;
    }

    .ant-tabs-card-content,
    .ant-tabs-content-holder {
      padding: 1.5rem;
      max-height: calc(100vh - 150px);
    }

    .ant-tabs-bar,
    .ant-tabs-nav {
      margin: 0;
    }
  }
`

CardTabs.TabPane = AntdTabs.TabPane

CardTabs.propTypes = {
  type: t.string,
}

export { CardTabs }
