import styled from 'styled-components'
import { Layout, Drawer as AntdDrawer } from 'antd'
import { media, getColorVariant } from 'resources'

const Sider = styled(Layout.Sider)`
  position: fixed;
  height: 100%;
  background: ${props => props.background};

  >.ant-layout-sider-children {
    display: flex;
    flex-flow: column wrap;
  }

  ${media.lessThan('smallDesktop')`
    display: none;
  `}
`

const Drawer = styled(AntdDrawer)`
  display: none;

  .ant-drawer-body {
    padding: 0;
    background: #07081d;
    display: flex;
    flex-flow: column wrap;
  }

  >.ant-drawer-content-wrapper{
    max-width: 400px;
    min-width: 300px;
  }

  ${media.lessThan('smallDesktop')`
    display: block;
  `}
`

const Header = styled.div`
  padding: 2rem 1.25rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  padding: 1rem 0;
  flex: 3;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
    border: none;
  }

  ::-webkit-scrollbar-thumb {
    background:#4e5074;
    border-radius: 8px;
  }
`

const LogoutButton = styled.button`
  font-size: 1rem;
  color: ${getColorVariant('gray', 'main')};
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  margin: 0;
  background: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  border-top: solid 2px #242432;

  > span.anticon {
    transform: rotate(-180deg);

    margin-right: 1.25rem;
    color: ${getColorVariant('gray', 'main')};
    font-size: 1.25rem;
  }
`

export { Sider, Drawer, Header, Content, LogoutButton }
