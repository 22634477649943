import { gql, useQuery } from '@apollo/client'
import { getNodes } from 'resources'
import { pipe, map } from 'ramda'

/* @TODO: Alterar mock da thumbnail do vídeo para utilizar a imagem retornada pelo back-end
 * Clubouse Story → [946]
*/
const mockedImage = 'http://ferretto.public.dev.s3.amazonaws.com/09a5ab66-e914-4345-a694-8109b60b533f.png'

const useVideosFetcher = () => {
  const { data, loading } = useQuery(VIDEOS_QUERY, {
    variables: {},
    fetchPolicy: 'no-cache',
  })

  return {
    videos: normalizeVideos(data),
    loading,
  }
}

const formatSeconds = seconds => new Date(seconds * 1000).toISOString().substr(11, 8)

const parseVideo = ({ timeInSeconds, ...video }) => ({
  ...video,
  time: formatSeconds(timeInSeconds),
  thumbnail: mockedImage,
})

const normalizeVideos = pipe(
  getNodes('videos'),
  map(parseVideo)
)

export const VIDEOS_QUERY = gql`
  {
    videos {
      nodes {
        id
        title
        thumbnail
        timeInSeconds
      }
    }
  }
`

export { useVideosFetcher }
