import { set, get, del } from 'idb-keyval'

const USER_TOKEN = 'USER_TOKEN'

const setToken = token => set(USER_TOKEN, token)

const getToken = () => get(USER_TOKEN)

const removeToken = () => del(USER_TOKEN)

export { setToken, getToken, removeToken }
