import React, { useMemo } from 'react'
import t from 'prop-types'
import { Col, Input, Row, Typography } from 'antd'
import { getPrimaryVariant } from 'resources'
import styled from 'styled-components'
import { Form } from 'ui/form'
import { required } from 'resources/form-validation'

export const KnowledgeAreasTRIByDisciplines = ({
  disciplines,
  knowledgeAreas,
  selectedDisciplinesIds,
  context,
}) => {
  const selectedDisciplines = useMemo(
    () => disciplines?.filter((d) => selectedDisciplinesIds.includes(d.id)),
    [disciplines, selectedDisciplinesIds]
  )
  const selectedKnowledgeAreas = useMemo(
    () =>
      knowledgeAreas.filter((knowledgeArea) =>
        selectedDisciplines.some((d) => d.knowledgeAreaId === knowledgeArea.id)
      ),
    [knowledgeAreas, selectedDisciplines]
  )
  const disciplinesWithoutKnowledgeArea = useMemo(
    () =>
      selectedDisciplines.filter(
        (d) => !selectedKnowledgeAreas.some((ka) => ka.id === d.knowledgeAreaId)
      ),
    [selectedDisciplines, selectedKnowledgeAreas]
  )

  if (!context?.useTRIScore) return null

  return (
    <Wrapper>
      <Title level={4}>TRI</Title>
      {disciplinesWithoutKnowledgeArea.length > 0 && (
        <>
          <KnowledgeAreaTitle>
            Disciplinas sem área de conhecimento
          </KnowledgeAreaTitle>{' '}
          <h4>
            {disciplinesWithoutKnowledgeArea.map((d) => d.name).join(', ')}
          </h4>
        </>
      )}
      {selectedKnowledgeAreas.map((ka) => {
        return (
          <Row key={ka.id} gutter={[12, 12]}>
            <Col xs={24}>
              <KnowledgeAreaTitle> {ka.name}</KnowledgeAreaTitle>
              <KnowledgeAreaSubTitle>
                {selectedDisciplines
                  .filter((d) => d.knowledgeAreaId === ka.id)
                  .map((d) => d.name)
                  .join(', ')}
              </KnowledgeAreaSubTitle>
              <Form.Item
                type='hidden'
                name={['knowledgeAreaTRI', ka.id, 'knowledgeAreaId']}
                style={{ display: 'none' }}
              >
                <Input type='hidden' value={ka.id} />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label='Nota mínima'
                name={['knowledgeAreaTRI', ka.id, 'min']}
                rules={rules.tri.min}
              >
                <Input placeholder='Nota mínima' type='number' size='large' />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label='Nota máxima'
                name={['knowledgeAreaTRI', ka.id, 'max']}
                rules={rules.tri.max}
              >
                <Input placeholder='Nota mínima' type='number' size='large' />
              </Form.Item>
            </Col>
          </Row>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  background: #e5ecfe;
  padding: 1.5rem 2rem;
`
const Title = styled(Typography.Title)`
  && {
    color: ${getPrimaryVariant('main')};
    margin-bottom: 1.5rem;
  }
`

const KnowledgeAreaTitle = styled.h3`
  color: ${getPrimaryVariant('main')};
`

const KnowledgeAreaSubTitle = styled.h4`
  margin-bottom: 0;
`

KnowledgeAreasTRIByDisciplines.propTypes = {
  disciplines: t.array,
  knowledgeAreas: t.array,
  selectedDisciplinesIds: t.array,
  context: t.object,
}

const rules = {
  tri: {
    min: [required],
    max: [required],
  },
}
