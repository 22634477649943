import styled from 'styled-components'
import { getPrimaryVariant } from 'resources'
import { Input, Form } from 'antd'
import React from 'react'

export const Wrapper = styled.ul`
  margin: 0;
  padding: 0.5rem;
  min-height: 28rem;
  max-height: 28rem;
  overflow: auto;
`

export const Item = styled.li`
  list-style: none;
`

export const EmptyStateContainer = styled.div`
  min-height: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextArea = styled(({ bgColor, ...rest }) => <Input.TextArea {...rest} />)`
  background: ${({ bgColor }) => bgColor || '#f4f4f4'};
  resize: none;
`

export const FormItem = styled(Form.Item)`
  &&& label {
    color: ${getPrimaryVariant('main')};
    font-weight: 600;
    font-size: 0.95rem;
    letter-spacing: -.05em;
  }
`
