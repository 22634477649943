import { useState, useCallback } from 'react'

const useToggle = () => {
  const [state, setState] = useState(false)

  const toggleState = useCallback(() => {
    setState(!state)
  }, [state])

  return [toggleState, state]
}

export { useToggle }
