import { gql, useQuery } from '@apollo/client'
import { useCallback } from 'react'

const usePlansFetcher = (
  onFetchCompleted
) => {
  const onCompleted = useCallback(values => {
    onFetchCompleted && onFetchCompleted(values.plans)
  }, [onFetchCompleted])

  const { data, loading } = useQuery(PLANS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted,
  })

  return {
    plans: data?.checkoutPlans || [],
    loading,
  }
}

const PLANS_QUERY = gql`
  {
    checkoutPlans {
      id
      name
    }
  }
`

export { usePlansFetcher }
