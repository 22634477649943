import React from 'react'
import styled, { css } from 'styled-components'
import t from 'prop-types'
import { Card as AntdCard } from 'antd'

import { DragIcon } from './drag-icon'
import { getColorVariant } from 'resources'

const DraggableCard = ({ children, isDragging, dragHandleProps, ...props }) => (
  <Card isDragging={isDragging} {...props}>
    <Container>
      {children}
    </Container>
    <DragIcon {...dragHandleProps} isDragging={isDragging} />
  </Card>
)

const Card = styled(AntdCard)`
  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  ${props => props.isDragging && css`
    transform: rotate(1.25deg);
    border: solid 2px ${getColorVariant('primary', 'main')};
    .ant-card-body {
      background: ${getColorVariant('primary', 'light')};
    }
  `}
`

const Container = styled.div`
  flex: 3;
`

DraggableCard.propTypes = {
  children: t.node.isRequired,
  isDragging: t.bool.isRequired,
  dragHandleProps: t.object.isRequired,
}

export { DraggableCard }
