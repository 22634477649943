import React from 'react'
import { Modal, notification } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

export const openDeleteDialog = ({
  title = 'Excluir item',
  content = 'Tem certeza que deseja remover este item?',
  icon: Icon,
  onConfirm,
  onCancel,
}) => confirm({
  title,
  content,
  icon: Icon ? <Icon /> : <ExclamationCircleOutlined />,
  onOk: () => onConfirm().then(showSuccessMessage),
  onCancel,
  okText: 'Excluir',
  okType: 'danger',
  cancelText: 'Cancelar',
})

const showSuccessMessage = () => notification.success({
  message: 'Tudo certo!',
  description: 'Registro excluído com sucesso.',
})
