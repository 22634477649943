import { gql, useMutation } from '@apollo/client'

const UPLOAD_MUTATION = gql`
  mutation UploadFile($file: Upload!){
    upload(file: $file) {
      location
    }
  }
`

const useEditor = () => {
  const [uploadMutation] = useMutation(UPLOAD_MUTATION)

  const handleFileUpload = async (blobInfo, onSuccess, onFailure) => {
    const blob = blobInfo.blob()
    const file = new File([blob], 'arquivo-editor', { type: blob.type })

    try {
      const variables = { file }
      const { data } = await uploadMutation({ variables })
      onSuccess(data.upload.location)
    } catch (error) {
      onFailure('Ocorreu um erro ao tentar fazer upload do arquivo.')
    }
  }

  return {
    handleFileUpload,
  }
}

export { useEditor }
