import React from 'react'
import t from 'prop-types'
import { ResourceTypes } from 'resources'
import { required } from 'resources/form-validation'
import { FormDrawer, Form, Select, Option, SimulatedsSelect, ClassesSelect, SubjectSelect, DisciplineSelect, PdfsSelect, VideoInput } from 'ui'
import { useAddItem } from './use-add-item'
import { Row, Col, Button, Tag, Input } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { dec, inc } from 'ramda'
import { makeArray } from 'resources/composable'

import { useSubjectsFetcher } from '../subject-select/use-subjects-fetcher'

const AddClassGroupItemDrawer = ({ visible, onCancel, onClose, onConfirm, typeClass = 'discipline', tabs, updateAmountOfVideos, disciplineId }) => {
  const { form, onSubmit, setType, type, isLoading, setSimulatedType, simulatedType } = useAddItem({ onConfirm })

  return (
    <FormDrawer
      width={500}
      loading={isLoading}
      title='Adicionar item'
      visible={visible}
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={form.submit}
    >
      <Form onFinish={onSubmit} form={form} layout='vertical'>
        <Form.Item label='Tipo de item' name='type' rules={rules.type}>
          <Select
            placeholder='Selecione um tipo'
            size='large'
            onChange={setType}
          >
            {typeClass === 'discipline' && (<Option value={ResourceTypes.SIMULATED}>Simulado</Option>)}
            <Option value={ResourceTypes.QUESTIONS_SUBJECT}>Assunto de questão</Option>
            <Option value={ResourceTypes.CLASS}>Aula</Option>
            <Option value={ResourceTypes.PDFS}>PDF</Option>
            <Option value={ResourceTypes.VIDEO_GROUP}>Grupo de vídeo</Option>
          </Select>
        </Form.Item>

        {type === ResourceTypes.SIMULATED && (
          <>
            <Form.Item label='Tipo de simulado' name='simulatedType' rules={rules.type}>
              <Select
                placeholder='Selecione um tipo'
                size='large'
                onChange={setSimulatedType}
              >
                <Option value='random'>Perguntas aleatórias</Option>
                <Option value='simulated'>Simulado Pronto</Option>
              </Select>
            </Form.Item>
            {simulatedType === 'simulated' && (

              <Form.Item label='Simulado' name='simulated' rules={rules.type}>
                <SimulatedsSelect
                  size='large'
                  valueName='slug'
                  placeholder='Selecione um simulado'
                />
              </Form.Item>
            )}
            {simulatedType === 'random' && (
              <Form.Item label='Assunto' name='simulatedSubject' rules={rules.type}>
                <SubjectSelectSimulated
                  size='large'
                  onlyParents
                  valueName='id'
                  placeholder='Assunto'
                  disciplineId={disciplineId}
                />
              </Form.Item>
            )}
          </>
        )}

        {type === ResourceTypes.CLASS && (
          <Form.Item label='Aula' name='class' rules={rules.class}>
            <ClassesSelect
              size='large'
              valueName='slug'
              placeholder='Selecione uma aula'
            />
          </Form.Item>
        )}

        {type === ResourceTypes.QUESTIONS_SUBJECT && (
          <>
            <Form.Item name='disciplineSlug' label='Disciplina' rules={rules.class}>
              <DisciplineSelect
                size='large'
                valueName='slug'
                placeholder='Selecione uma disciplina'
              />
            </Form.Item>

            <Form.Item style={{ margin: 0 }} shouldUpdate={watchField('disciplineSlug')} rules={rules.class}>
              {({ getFieldValue }) => (
                <Form.Item name='parentSubjectSlug' label='Assunto primário' rules={rules.class}>
                  <SubjectSelect
                    size='large'
                    valueName='slug'
                    onlyParents
                    placeholder='Selecione um assunto'
                    disabled={!getFieldValue('disciplineSlug')}
                    disciplineSlug={getFieldValue('disciplineSlug')}
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item style={{ margin: 0 }} shouldUpdate={watchField('parentSubjectSlug')}>
              {({ getFieldValue }) => (
                <Form.Item
                  label='Assunto secundário'
                  name='subject'
                  rules={rules.subject}
                >
                  <SubjectSelect
                    valueName='slug'
                    parentSlug={getFieldValue('parentSubjectSlug')}
                    disabled={!getFieldValue('parentSubjectSlug')}
                    size='large'
                    placeholder='Selecione um assunto'
                  />
                </Form.Item>
              )}

            </Form.Item>
          </>
        )}

        {type === ResourceTypes.PDFS && (
          <>
            <Form.Item name='disciplineId' label='Disciplina' rules={rules.class}>
              <DisciplineSelect
                size='large'
                placeholder='Selecione uma disciplina'
                onChange={() => {
                  form.resetFields(['pdfType', 'pdfsId'])
                }}
              />
            </Form.Item>
            <Form.Item style={{ margin: 0 }} shouldUpdate={watchField('disciplineId')} rules={rules.pdfType}>
              {({ getFieldValue }) => (
                <Form.Item name='pdfType' label='Tipo do PDF' rules={rules.pdfType}>
                  <Select
                    size='large'
                    placeholder='Selecione o tipo do PDF'
                    allowClear
                    disabled={!getFieldValue('disciplineId')}
                    onChange={() => {
                      form.resetFields(['pdfsId'])
                    }}
                  >
                    <Option value='GRAPHIC_SUMMARIES'>Resumo gráfico</Option>
                    <Option value='QUESTIONS'>Questões</Option>
                  </Select>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item style={{ margin: 0 }} shouldUpdate={watchField('pdfType')} rules={rules.pdfsId}>
              {({ getFieldValue }) => (
                <Form.Item name='pdfsId' label='PDF' rules={rules.pdfsId}>
                  <PdfsSelect
                    size='large'
                    placeholder='Selecione um pdf'
                    disabled={!getFieldValue('pdfType')}
                    disciplineId={getFieldValue('disciplineId')}
                    pdfType={getFieldValue('pdfType')}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        )}

        {type === ResourceTypes.VIDEO_GROUP && (
          <>
            <Form.Item name='videoGroupTitle' label='Titulo do grupo de vídeo' rules={rules.pdfsId}>

              <Input placeholder='Titulo do grupo de vídeo' type='text' size='large' />
            </Form.Item>
            {tabs.map((tab, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <Row gutter={[12, 12]} justify='end'>
                      <Col xs={12} sm={12} md={6}>
                        <Button
                          block
                          onClick={() => updateAmountOfVideos(tab.id, inc, false)}
                          type='primary'
                          icon={<PlusOutlined />}
                        >
                          vídeo
                        </Button>
                      </Col>
                      <Col xs={12} sm={12} md={6}>
                        <Button
                          disabled={tab.amountOfVideos === 1}
                          block
                          onClick={() => updateAmountOfVideos(tab.id, dec, true)}
                          type='danger'
                          icon={<MinusOutlined />}
                        >
                          vídeo
                        </Button>
                      </Col>
                    </Row>

                    {makeArray(tab.amountOfVideos).map((row, id) => {
                      return (
                        <Row key={id} gutter={[24, 12]} justify='space-between'>
                          <Col xs={1}>
                            <Tag color='geekblue'>{id + 1}º</Tag>
                          </Col>
                          <Col xs={23}>
                            <Form.Item
                              label='ID do Vídeo'
                              name={['videos', index, 'videos', id, 'id']}
                              rules={[required]}
                            >
                              <VideoInput editTitle form={form} tabs={tabs} index={index} position={id} />
                            </Form.Item>
                          </Col>
                        </Row>
                      )
                    })}
                  </Col>
                </Row>
              )
            })}
          </>
        )}

      </Form>
    </FormDrawer>
  )
}

const watchField = name => (previous, current) => previous[name] !== current[name]

const rules = {
  type: [required],
  class: [required],
  parentSubject: [required],
  pdfsId: [required],
  pdfType: [required],
}

AddClassGroupItemDrawer.propTypes = {
  onCancel: t.func.isRequired,
  onClose: t.func.isRequired,
  onConfirm: t.func.isRequired,
  visible: t.bool.isRequired,
  typeClass: t.string,
  tabs: t.array,
  updateAmountOfVideos: t.func,
  disciplineId: t.string,
}

const SubjectSelectSimulated = ({
  loading,
  onlyParents,
  parentSlug,
  parentId,
  disciplineSlug,
  disciplineId,
  valueName = 'slug',
  ...antdProps
}) => {
  const { subjects } = useSubjectsFetcher({ disciplineSlug, onlyParents, parentSlug, parentId, disciplineId })
  return (
    <Select
      {...antdProps}
      data-testid='subjects-select'
      emptyText='Nenhum assunto encontrado'
      loading={loading}
    >
      {subjects.map((row, i) => {
        return (
          <Option value={JSON.stringify(row)} key={row[valueName]}>{row.name}</Option>
        )
      })}
    </Select>
  )
}

SubjectSelectSimulated.propTypes = {
  loading: t.bool,
  onlyParents: t.bool,
  parentSlug: t.string,
  parentId: t.string,
  valueName: t.oneOf(['id', 'slug']),
  disciplineSlug: t.string,
  disciplineId: t.string,
}
export { AddClassGroupItemDrawer }
