import styled from 'styled-components'
import { getPrimaryVariant, getColorVariant } from 'resources'
import { Typography } from 'antd'

export const PreviewContainer = styled.div`
  background: #E5ECFE;
  border-radius: 2px;
  padding: 1.25rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  position: relative;
`

export const Order = styled(Typography.Text)`
  color: ${getPrimaryVariant('main')};
  font-size: 1.5rem;
  margin-right: 2rem;
`

export const Title = styled(Typography.Text)`
  && {
    color: ${getPrimaryVariant('main')};
    font-size: 1rem;
    display: block;
  }
`

export const InputContainer = styled.div`
  display: flex;
`

export const Wrapper = styled.div`
`

export const ThumbnailContainer = styled.div`
  width: 150px;
  height: 75px;
  margin-right: 1.15rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px  ${props => props.isPlaceholder ? getPrimaryVariant('main') : 'transparent'};

`

export const Thumbnail = styled.img`
  height: 100%;
`

export const RefetchButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 0;
  background: 0;
  outline: 0;
  cursor: pointer;
  .anticon {
    font-size: 1.5rem;
    color: ${getPrimaryVariant('main')};
  }
`

export const LoadingWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(197 209 244 / 80%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`
export const TitleWrapper = styled.div`
  width: 100%;
`

export const InfosWrapper = styled.div`
  padding: 1.5px 1px;
`
export const RemoveButton = styled.button`
border: 0;
background: 0;
padding: 0;
margin: 0;
position: absolute;
right: 1rem;
outline: none;
cursor: pointer;
.anticon {
  color: ${getColorVariant('danger', 'main')}
}
`
