import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

import { useSimulatedsFetcher } from './use-simulateds-fetcher'

const SimulatedsSelect = ({
  loading,
  valueName = 'id',
  isPublished = false,
  contextId,
  renderLabel,
  ...antdProps
}) => {
  const { simulateds } = useSimulatedsFetcher(isPublished, contextId)

  return (
    <Select
      {...antdProps}
      loading={loading}
      emptyText='Nenhum simulado encontrado'
    >
      {simulateds.map((simulated) => (
        <Option value={simulated[valueName]} key={simulated[valueName]}>
          {renderLabel ? renderLabel(simulated) : simulated.title}
        </Option>
      ))}
    </Select>
  )
}

SimulatedsSelect.propTypes = {
  loading: t.bool,
  valueName: t.oneOf(['id', 'slug']),
  isPublished: t.bool,
  contextId: t.number,
  renderLabel: t.func,
}

export { SimulatedsSelect }
