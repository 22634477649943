import React, { useMemo } from 'react'
import t from 'prop-types'
import { EmptyMessage, QuestionsSelect } from 'ui'
import { makeArray } from 'resources/composable'

import { Wrapper, FormItem, Position, Title, QuestionItem } from './components'
import { CONTEXTS } from 'resources'

const SimulatedQuestionList = ({
  amount = '0',
  contextId = CONTEXTS.IDs.DEFAULT,
}) => {
  const list = useMemo(() => listOfAmount(amount), [amount])

  return (
    <Wrapper>
      <Title level={4}> Questões</Title>
      {list.length > 0 &&
        list.map((_, id) => (
          <QuestionItem key={id}>
            <Position>{id + 1}</Position>
            <FormItem name={`question-${id}`}>
              <QuestionsSelect
                contextId={contextId}
                placeholder='Selecione uma questão'
                size='large'
              />
            </FormItem>
          </QuestionItem>
        ))}
      {list.length === 0 && (
        <EmptyMessage description='Informe a quantidade de questões' />
      )}
    </Wrapper>
  )
}

const listOfAmount = (amount) => (+amount > 0 ? makeArray(amount) : [])

SimulatedQuestionList.propTypes = {
  amount: t.number,
  contextId: t.number,
}

export { SimulatedQuestionList }
