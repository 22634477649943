import { gql, useQuery } from '@apollo/client'
import { getNodes } from 'resources'

export const INSTITUTIONS_QUERY = gql`
  {
    educationalInstitutions(filter: { showAll: true }){
    nodes {
      id
      name
    }
  }
}
`

const useInstitutionsFetcher = () => {
  const { data, loading } = useQuery(INSTITUTIONS_QUERY, {
    variables: {},
  })

  return {
    institutions: getNodes('educationalInstitutions', data),
    isFetching: loading,
  }
}

export { useInstitutionsFetcher }
