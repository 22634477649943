import React from 'react'
import { getColorVariant } from 'resources'
import styled from 'styled-components'
import { Tabs as AntdTabs } from 'antd'

const Tabs = props => <CustomTabs {...props} />

const CustomTabs = styled(AntdTabs)`
  .ant-tabs-bar {
    border-bottom: 2px solid ${getColorVariant('gray', 'light')};
  }
`

Tabs.TabPane = AntdTabs.TabPane

export { Tabs }
