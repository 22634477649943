import React from 'react'
import styled from 'styled-components'
import { Typography } from 'antd'

import emptyIcon from './empty-state.svg'

const EmptyWeek = () => (
  <Wrapper>
    <Image alt='Semana' src={emptyIcon} />
    <Text>Não há itens nesta semana</Text>
  </Wrapper>
)
const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width: 11rem;
`

const Text = styled(Typography.Text)`
  color: #9BA0A6;
  font-style: normal;
  font-weight: 600;
  font-size: 1.15rem;
  text-align: center;
  letter-spacing: -0.05em;
  margin-top: 1rem;
`

export { EmptyWeek }
