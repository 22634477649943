import React from 'react'
import t from 'prop-types'
import { useTheme } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { getColor } from 'resources'

import { Menu as AntdMenu } from './components'
import { SubMenu } from './sub-menu'
import { MenuItem } from './menu-item'

const Menu = ({ children }) => {
  const theme = useTheme()
  const location = useLocation()
  return (
    <AntdMenu
      theme='dark'
      background={getColor('black', { theme })}
      activeKey={location.pathname}
      selectedKeys={[location.pathname]}
      mode='inline'
    >
      {children}
    </AntdMenu>
  )
}

Menu.SubMenu = SubMenu
Menu.Item = MenuItem

Menu.propTypes = {
  children: t.node.isRequired,
}

export { Menu }
