import React, { useState, useEffect } from 'react'
import t from 'prop-types'
import { InboxOutlined, FilePdfOutlined, DeleteFilled } from '@ant-design/icons'

import {
  Wrapper,
  Uploader,
  File,
  Label,
  RemoveButton,
  Text,
} from './components'

const FileUploader = ({
  isEditing,
  value,
  removeFiles = false,
  hideDeleteButton = false,
  accept = '.pdf, .docx, .csv',
  ...props
}) => {
  const [files, setFiles] = useState([])

  const beforeUpload = (file) => {
    setFiles([file])
    return false
  }

  useEffect(() => {
    if (isEditing && value && !value.file) {
      setFiles([
        {
          name:
            typeof value === 'string'
              ? `${value.length >= 30 ? '...' : ''}${value.substr(
                  value.length - 30,
                  value.length
                )}`
              : 'arquivo.pdf',
        },
      ])
    }
    if (removeFiles && value && !value.file) {
      setFiles([])
    }
  }, [isEditing, removeFiles, value])

  const removeFile = () => {
    setFiles([])
    props.onChange()
  }

  if (files.length === 0) {
    return (
      <Uploader
        visible={!files.length}
        accept={accept}
        listType='picture'
        showUploadList={false}
        fileList={files}
        beforeUpload={beforeUpload}
        value={value}
        {...props}
      >
        <Wrapper>
          <InboxOutlined />
          <Text>Arraste e solte o arquivo aqui</Text>
        </Wrapper>
      </Uploader>
    )
  }

  return (
    <File>
      <FilePdfOutlined />
      <Label>{files[0].name}</Label>
      {!hideDeleteButton && (
        <RemoveButton onClick={removeFile}>
          <DeleteFilled />
        </RemoveButton>
      )}
    </File>
  )
}

FileUploader.propTypes = {
  isEditing: t.bool,
  value: t.string,
  onChange: t.func,
  removeFiles: t.bool,
  hideDeleteButton: t.bool,
  accept: t.string,
}

export { FileUploader }
