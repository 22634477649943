import React, { useEffect } from 'react'
import { Switch } from 'antd'

/**
 * Foi necessária a criação deste componente pois o antd hora retorna string, hora retorna boolean.
 * Mistérios da vida.
 */
const FixedSwitch = (props) => {
  function newOnChange(checked) {
    props.onChange(`${checked}` === 'true')
  }

  useEffect(() => {
    if (typeof props.checked === 'string') {
      props.onChange(`${props.checked}` === 'true')
    }
  }, [props, props.checked, props.onChange])

  return <Switch {...props} onChange={newOnChange} />
}

FixedSwitch.propTypes = Switch.propTypes

export { FixedSwitch }
