/* eslint-disable no-unused-expressions */
import React, { useCallback } from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useContextsFetcher } from './use-context-fetcher'

/**
 * @type Select
 */
const ContextSelect = ({
  loading = false,
  valueName = 'id',
  onFetchCompleted,
  getValue,
  onSelect,
  onContextChange,
  ...antdProps
}) => {
  const { contexts, loading: isFetching } = useContextsFetcher(onFetchCompleted)

  const toggleContextChange = useCallback(
    (value) => {
      onContextChange?.(
        contexts.find((context) => context[valueName] === value)
      )
      onSelect?.(value)
    },
    [onContextChange, onSelect, contexts, valueName]
  )

  return (
    <Select
      {...antdProps}
      render='true'
      defaultActiveFirstOption
      loading={isFetching || loading}
      emptyText='Nenhum tipo de questão encontrada'
      onSelect={toggleContextChange}
      allowClear
    >
      {contexts.map((context) => (
        <Option
          value={getValue ? getValue(context) : context[valueName]}
          key={context[valueName]}
        >
          {context.name}
        </Option>
      ))}
    </Select>
  )
}

ContextSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  getValue: t.func,
  onFetchCompleted: t.func,
  onSelect: t.func,
  onContextChange: t.func,
}

export { ContextSelect }
