import React from 'react'
import StudyPlanList from './components/list'
import { Title, WeekHeader, WeekWrapper } from './components'
import t from 'prop-types'

const Column = ({ title, studyPlans, id, isScrollable, isCombineEnabled, useClone, onAdd, flag, setFlag, onRemove }) => {
  return (
    <WeekWrapper>
      <WeekHeader>
        <Title aria-label={`${title} studyPlan list`}>
          {title}
        </Title>
      </WeekHeader>
      <StudyPlanList
        listId={id}
        listType='QUOTE'
        studyPlans={studyPlans}
        internalScroll={isScrollable}
        isCombineEnabled={isCombineEnabled}
        useClone={Boolean(useClone)}
        id={id}
        onAdd={onAdd}
        flag={flag}
        setFlag={setFlag}
        onRemove={onRemove}
      />
    </WeekWrapper>
  )
}

export default Column

Column.propTypes = {
  id: t.number.isRequired,
  onAdd: t.func.isRequired,
  onRemove: t.func.isRequired,
  flag: t.bool,
  setFlag: t.func.isRequired,
  title: t.string,
  studyPlans: t.object,
  isCombineEnabled: t.bool,
  useClone: t.bool,
  isScrollable: t.bool,
}
