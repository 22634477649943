import { useState } from 'react'
import { ResourceTypes } from 'resources'
import { Form } from 'ui'
import client from 'apollo-client'
import { FIND_CLASS_QUERY, FIND_SUBJECT_QUERY, FIND_SIMULATED_QUERY, PDFS_QUERY, VIDEO_GROUP_QUERY } from './graphql'
import { T, equals as eq, always, path, pipe, isNil, cond, prop, propEq, head, dissoc, assoc, evolve, chain, map, addIndex } from 'ramda'

const useAddItem = ({ onConfirm }) => {
  const [isLoading, toggleLoading] = useState(false)
  const [type, setType] = useState(null)
  const [simulatedType, setSimulatedType] = useState(null)
  const [form] = Form.useForm()

  const onSubmit = async values => {
    if (values.simulatedType === 'random') {
      const parseValues = JSON.parse(values.simulatedSubject)
      onConfirm({
        slug: parseValues.id,
        title: `Simulado ${parseValues.name}`,
        type: values.type,
      })
      form.resetFields()
      setType(null)
      toggleLoading(false)
      return
    }
    try {
      toggleLoading(true)
      const getResource = getResourceOfType(values.type)
      const slug = getSlug(values)
      const result = await queryResource(values.type, slug)
      let resource = result.length > 0 ? result.map((row) => getResource(row.data)) : getResource(result.data)

      if (resource?.nodes) {
        resource = await normalizePdfs(resource)
      }

      if (values.type === ResourceTypes.VIDEO_GROUP) {
        const videos = normalizeVideos(resource, values)
        onConfirm({
          ...videos,
          type: values.type,
        })
      } else {
        onConfirm({
          ...resource,
          type: values.type,
        })
      }
      form.resetFields()
      setType(null)
      toggleLoading(false)
    } catch (error) {
      console.error(error)
      toggleLoading(false)
    }
  }

  return {
    form,
    onSubmit,
    isLoading,
    setType,
    type,
    setSimulatedType,
    simulatedType,
  }
}

const typeEq = propEq('type')

const getPath = (...args) => {
  return always(path(args))
}

const isParentSubject = pipe(
  prop('subject'),
  isNil
)

const getResourceOfType = cond([
  [eq(ResourceTypes.CLASS), getPath('class')],
  [eq(ResourceTypes.SIMULATED), getPath('simulated', 'meta')],
  [eq(ResourceTypes.QUESTIONS_SUBJECT), getPath('subject')],
  [eq(ResourceTypes.PDFS), getPath('pdfs')],
  [eq(ResourceTypes.VIDEO_GROUP), getPath('video')],
])

const getSubjectSlug = cond([
  [isParentSubject, prop('parentSubjectSlug')],
  [T, prop('subject')],
])

const getVideoIds = pipe(
  prop('videos'),
  chain(prop('videos')),
  map(prop('id'))
)

const getSlug = cond([
  [typeEq(ResourceTypes.CLASS), prop('class')],
  [typeEq(ResourceTypes.SIMULATED), prop('simulated')],
  [typeEq(ResourceTypes.QUESTIONS_SUBJECT), getSubjectSlug],
  [typeEq(ResourceTypes.PDFS), prop('pdfsId')],
  [typeEq(ResourceTypes.VIDEO_GROUP), getVideoIds],
])

const makeQuery = (QUERY, slug, type) => {
  let filter = { }
  type === ResourceTypes.PDFS ? filter = { filter: { id: slug } } : type === ResourceTypes.VIDEO_GROUP ? filter = { id: slug } : filter = { slug, is_published: true }

  return client.query({
    query: QUERY,
    variables: filter,
  })
}

const queryResource = async (type, slug) => {
  const query = {
    [ResourceTypes.CLASS]: FIND_CLASS_QUERY,
    [ResourceTypes.QUESTIONS_SUBJECT]: FIND_SUBJECT_QUERY,
    [ResourceTypes.SIMULATED]: FIND_SIMULATED_QUERY,
    [ResourceTypes.PDFS]: PDFS_QUERY,
    [ResourceTypes.VIDEO_GROUP]: VIDEO_GROUP_QUERY,
  }[type]

  if (!query) throw new Error('Invalid resource type.')

  if (type === ResourceTypes.VIDEO_GROUP) {
    const data = Promise.all(slug.map((row) => {
      return makeQuery(query, row, type)
    }))
    return data
  }
  return makeQuery(query, slug, type)
}

const normalizePdfs = (resource) => {
  return pipe(
    prop('nodes'),
    head,
    evolve({
      discipline: prop('name'),
      nodes: always(undefined),
    }),
    dissoc('nodes'),
    assoc('type', 'PDFS')
  )(resource)
}

const normalizeVideos = (resource, values) => {
  const { videosTitle, videoGroupTitle } = values
  const mapIndexed = addIndex(map)
  const transformVideos = mapIndexed((video, index) => ({
    ...video,
    videoGroupTitle,
    title: videosTitle[`videos_0_videos_${index}_id`] !== undefined ? videosTitle[`videos_0_videos_${index}_id`] : video.title,
  }))

  const newValues = {
    videos: transformVideos(resource || []),
  }

  return newValues
}

export { useAddItem }
