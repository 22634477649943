import { gql, useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { getNodes } from 'resources'

const useStatesFetcher = (
  onFetchCompleted
) => {
  const onCompleted = useCallback(values => {
    onFetchCompleted && onFetchCompleted(values.states.nodes)
  }, [onFetchCompleted])

  const { data, loading } = useQuery(STATES_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted,
  })

  return {
    states: getNodes('states', data),
    loading,
  }
}

const STATES_QUERY = gql`
  {
    states(filter: { showAll: true }){
      nodes {
        id
        slug
        name
      }
    }
  }
`

export { useStatesFetcher }
