import { useQuery, gql } from '@apollo/client'
import { useState } from 'react'
import { getNodes } from 'resources'
import { useDebouncedCallback } from 'use-debounce/lib'

const useClasses = () => {
  const [isSearching, setSearching] = useState(false)
  const { data, refetch, loading } = useQuery(CLASSES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      pagination,
    },
  })

  const handleSearch = async value => {
    setSearching(true)
    await refetch({
      pagination,
      filter: { code: value || null },
    })
    setSearching(false)
  }

  const debounced = useDebouncedCallback(handleSearch, 1000)

  return {
    classes: isSearching ? [] : getNodes('classes', data),
    handleSearch: debounced.callback,
    isSearching,
    isFetching: loading,
  }
}

const pagination = {
  perPage: 10,
}

const CLASSES_QUERY = gql`
  query GetClasses($filter: FilterClassesInput, $pagination: PaginationInput!){
    classes(filter: $filter, pagination: $pagination){
      nodes {
        id
        code
        slug
        title
      }
    }
  }
`

export { useClasses }
