import { gql } from '@apollo/client'

export const VIDEO_BY_ID_QUERY = gql`
  mutation VideoById($id: ID!) {
    createVideo(id: $id){
      id
      title
      timeInSeconds
      thumbnail
    }
  }
`
