import React from 'react'
import styled from 'styled-components'
import { ResourceTypes } from 'resources'
import t from 'prop-types'

import { ResourceItem, ParentSubjectItem, PdfsItem, VideoGroupItem } from '../resource-item'

const StudyPlanItem = (props) => {
  const { studyPlan, isDragging, isGroupedOver, provided, isClone, index, order, onRemove } = props
  return (
    <Container
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      data-is-dragging={isDragging}
      data-testid={studyPlan?.id}
      data-index={index}
    >
      {studyPlan.item.parentSubject && Object.keys(studyPlan.item.parentSubject).length > 0
        ? (
          <ParentSubjectItem
            onRemove={_ => onRemove(order)}
            id={order}
            title={studyPlan.item.name ? studyPlan.item.name : studyPlan.item.title}
            parentSubject={studyPlan.item.parentSubject}
          />
        )

        : studyPlan.item.sub ? (
          <ResourceItem
            onRemove={_ => onRemove(order)}
            id={order}
            type={studyPlan.type}
            title={`Simulado ${studyPlan.item.sub[0].name}`}
          />
        )

          : studyPlan.type === ResourceTypes.PDFS ? (
            <PdfsItem
              onRemove={_ => onRemove(order)}
              id={order}
              title={studyPlan.item.title}
              discipline={studyPlan.item.discipline?.name || studyPlan.item.discipline}
            />
          )
            : studyPlan.type === ResourceTypes.VIDEO_GROUP ? (
              <VideoGroupItem
                onRemove={_ => onRemove(order)}
                key={order}
                id={order}
                videos={studyPlan.item.videos}
                title={studyPlan.item?.videoGroupTitle || studyPlan.item.videos[0].videoGroupTitle}
              />
            )
              : (
                <ResourceItem
                  onRemove={_ => onRemove(order)}
                  id={order}
                  type={studyPlan.type}
                  title={studyPlan.item.title || studyPlan.item.name}
                />
              )}
    </Container>
  )
}

export default React.memo(StudyPlanItem)

const getBackgroundColor = (isDragging, isGroupedOver, authorColors) => {
  if (isDragging) {
    return '#ccc'
  }

  if (isGroupedOver) {
    return '#EBECF0'
  }

  return '#FFFFFF'
}

const getBorderColor = (isDragging, authorColors) =>
  isDragging ? '#ccc' : 'transparent'

const Container = styled.a`
  border-radius: 2px;
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props.isDragging, props.colors)};
  /* background-color: ${(props) =>
    getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)}; */
  box-shadow: ${({ isDragging }) => (isDragging ? '2px 2px 1px #A5ADBA' : 'none')};
  box-sizing: border-box;
  /* padding: 8px; */
  min-height: 40px;
  margin-bottom: 8px;
  user-select: none;

  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }

  display: flex;
`

StudyPlanItem.propTypes = {
  id: t.number.isRequired,
  onRemove: t.func.isRequired,
  title: t.string,
  isGroupedOver: t.bool,
  order: t.number,
  isDragging: t.bool,
  studyPlan: t.object,
  provided: t.object,
  isClone: t.bool,
  index: t.number,
}
