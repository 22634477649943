import { gql } from '@apollo/client'

export const FIND_SIMULATED_QUERY = gql`
 query SimulatedBySlug($slug: String!){
   simulated(slug: $slug){
     meta {
      id
      slug
      title
    }
   }
  }

`

export const FIND_CLASS_QUERY = gql`
 query ClassBySlug($slug: String!){
    class(slug: $slug){
      id
      slug
      title
   }
 }
`

export const FIND_SUBJECT_QUERY = gql`
 query SubjectBySlug($slug: String!){
   subject(slug: $slug){
    id
    slug
    name
    parentSubject {
      id
      name
     }
   }
 }
`
export const PDFS_QUERY = gql`
  query GetPdfs($pagination: PaginationInput, $filter: FilterPdfsInput) {
    pdfs(pagination: $pagination, filter: $filter){
      nodes {
        id
        title
        file
        discipline {
          id
          name
        }
      }
    }
  }
`
export const VIDEO_GROUP_QUERY = gql`
  query VideoById($id: ID!) {
    video(id: $id){
      id
      title
      timeInSeconds
      thumbnail
    }
  }
`
