import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/react'

class GraphQLError extends Error {}

const captureGraphQLErrors = (errors = [], context) =>
  errors.forEach(e => {
    Sentry.withScope(scope => {
      scope.setTag('kind', e.code)
      scope.setExtra('Operation name', context.operation.operationName)
      scope.setExtra('Variables', context.operation.variables)
      scope.setExtra('API Message', e.message)
      Sentry.captureException(new GraphQLError(`GraphQL Error - Operation Name: ${context.operation.operationName}`))
    })
  })

// export const apolloSentry = onError(({ graphQLErrors, networkError, ...context }, a, b) => {
//   if (graphQLErrors?.length > 0) {
//     captureGraphQLErrors(graphQLErrors, context)
//   }

//   return context.forward(context.operation)
// })

export const apolloSentry = onError(({ graphQLErrors, ...context }) => {
  if (graphQLErrors?.length > 0) {
    captureGraphQLErrors(graphQLErrors, context)
  }
})
