import { gql } from '@apollo/client'
import { usePaginatedQuery } from 'resources'

export const PDFS_QUERY = gql`
 query GetPdfs(
    $pagination: PaginationInput
    $filter: FilterPdfsInput
  ) {
    pdfs(pagination: $pagination, filter: $filter) {
      pagination {
        total
        lastPage
        perPage
        page
      }
      nodes {
        id
        title
        file
        discipline {
          id
          name
          slug
        }
      }
    }
}

`

const usePdfsFetcher = ({ disciplineId, pdfType }) => {
  const { pdfs, loading } = usePaginatedQuery(PDFS_QUERY, {
    fetchPolicy: 'no-cache',
    nodeName: 'pdfs',
    page: 1,
    perPage: 30,
    variables: { filter: { discipline_id: disciplineId, pdf_type: pdfType } },
  })

  return {
    pdfs,
    isFetching: loading,
  }
}

export { usePdfsFetcher }
