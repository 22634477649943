import { assoc, reduce, keys, dissoc, is, pipe, path, defaultTo, curry, replace } from 'ramda'

export const getNodes = curry(
  (nodeName, data) => pipe(
    path([nodeName, 'nodes']),
    defaultTo([])
  )(data)
)

export const getPagination = curry(
  (nodeName, data) => pipe(
    path([nodeName, 'pagination']),
    defaultTo({
      page: 0,
      total: 0,
      lastPage: 0,
      perPage: 0,
    })
  )(data)
)

export const toDate = value => new Date(value)

export const formatWithIntl = value => new Intl.DateTimeFormat('pt-BR').format(value)

export const truncateText = ({ ending = '...', length = 30, text = '' }) => {
  if (text.length > length) return text.substring(0, length - ending.length) + ending

  return text
}

export const formatSeconds = seconds => new Date(seconds * 1000).toISOString().substr(11, 8)

export const getFileValue = value => value && value.file

export const normalizeFiles = curry((props, data) =>
  props.reduce((acc, prop) => {
    const field = acc[prop]
    const isNew = field && field.file && is(File, field.file)
    return isNew ? assoc(prop, field.file, acc) : dissoc(prop, acc)
  },
  data))

export const normalizeFiles2 = (props, prop) => {
  const isNew = props && props.file && is(File, props.file)
  return isNew ? assoc(prop, props.file) : null
}

export const normalizeClassGroupResource = ({ id, type, slug, title }) => ({
  itemId: id,
  type,
  slug,
})

export const toBool = value => value === 'true'

export const trim = value => value.trim()

export const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

export const boolToString = isPublished => isPublished !== null ? String(isPublished) : undefined

export const formatCpf = pipe(
  replace(/\D/g, ''),
  replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
)

export const formatPhoneNumber = pipe(
  replace(/\D/g, ''),
  replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4')
)

export const formatCurrency = value => new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).format(value)

export const formatDateWithTime = value => value && new Intl.DateTimeFormat('pt-BR', { timeSyle: 'long' }).format(new Date(value))

export const formatDateWithHour = value => value &&
  new Intl.DateTimeFormat('pt-BR', {
    timeStyle: 'medium',
    dateStyle: 'short',
  }).format(new Date(value))

export const hours = (value) => {
  const date = new Date(value)
  return date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() + ':' + (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()
}

export const currencyToFloat = pipe(
  replace(/\./g, ''),
  replace(/,/g, '.'),
  replace(/R\$/g, '')
)

export const hoursToSeconds = (value) => {
  const [hours, minutes, seconds] = value.split(':')
  return ((+hours * 60) + +minutes) * 60 + (+seconds)
}

export const stringToInt = value => parseInt(value)

export const cleanUtm = (value) => value.startsWith('?') ? value.substring(1) : value
