import styled from 'styled-components'
import { Layout, Typography } from 'antd'

import { media, spacing } from 'resources'

export const Container = styled(Layout.Header)`
  && {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${spacing(2)} ${spacing(7)} ${spacing(2)};

    ${media.lessThan('smallDesktop')`
      padding: ${spacing(2)} ${spacing(4)};
    `}
  }
`

export const Name = styled(Typography.Text)`
  margin-left: .85rem;
  font-weight: 600;
  font-size: 1rem;
`

export const BurgerButtonContainer = styled.div`
  visibility: hidden;

  ${media.lessThan('smallDesktop')`
    visibility: visible;
  `}
`
