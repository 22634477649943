import React, { useCallback, useState, useRef } from 'react'
import t from 'prop-types'
import styled from 'styled-components'
import { LinkOutlined, CopyOutlined } from '@ant-design/icons'
import { gql, useMutation } from '@apollo/client'
import { useError, getPrimaryVariant } from 'resources'
import { ActionItem } from 'ui/table-actions/action-item'
import { Modal, Button, Input, Spin } from 'antd'

export const RecoverPasswordLinkButton = ({ email }) => {
  const inputRef = useRef()
  const [dispatchError] = useError()
  const [status, setStatus] = useState(Status.NOT_CALLED)
  const [generateMutation] = useMutation(GENERATE_LINK_MUTATION)

  const copyLink = useCallback(() => {
    inputRef.current.select()
    document.execCommand('copy')
  }, [])

  const openDialog = useCallback(email => Modal.info({
    title: 'Link para recuperação de senha',
    content: (
      <Container>
        <Input
          ref={inputRef}
          readOnly
          defaultValue={email}
        />
        <Button onClick={_ => copyLink(email)} icon={<CopyOutlined />} />
      </Container>
    ),
    okText: 'Fechar',
    onOk: () => { },
  }), [copyLink])

  const generateLink = useCallback(() => {
    setStatus(Status.LOADING)

    const variables = { email }

    generateMutation({ variables })
      .then(result => {
        openDialog(result.data.email)
        setStatus(Status.COMPLETED)
      })
      .catch(dispatchError)
      .then(() => setTimeout(() => setStatus(Status.NOT_CALLED)))
  }, [dispatchError, email, generateMutation, openDialog])

  return (
    <ActionItem
      label='Gerar link para recuperação de senha'
      onClick={generateLink}
      icon={status === Status.LOADING ? () => <Spin size='small' /> : StyledIcon}
    />
  )
}

const Container = styled.div`
  display: flex;
  margin-top: 2rem;
`

const StyledIcon = styled(LinkOutlined)`
  color: ${getPrimaryVariant('main')};
  font-size: 1.10rem;
`

RecoverPasswordLinkButton.propTypes = {
  email: t.string.isRequired,
}

const Status = {
  LOADING: 0,
  COMPLETED: 1,
  NOT_CALLED: 2,
}

const GENERATE_LINK_MUTATION = gql`
  mutation GenerateLink($email: String!) {
    email: generateTokenToRecoveryPassword(email: $email)
  }
`
