import React from 'react'
import t from 'prop-types'
import { Form, DisciplineSelect, StudyScheduleSelect } from 'ui'
import { Row, Col, Input, Button } from 'antd'
import { required } from 'resources/form-validation'

const StudyListForm = ({ loading, isSubmitting, onDisciplinesChange, amountOfWeeks = 0, onWeeksChange, onSubmit, disableNumberOfWeeks }) => (
  <>
    <Row gutter={[24, 12]}>

      <Col xs={24} sm={8}>
        <Form.Item name='name' label='Nome' rules={rules.name}>
          <Input
            placeholder='Ex.: Lista de estudo Enem 2020'
            size='large'
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={8}>
        <Form.Item name='amountOfWeeks' label='Nº de Semanas' rules={rules.amountOfWeeks}>
          <Input
            type='number'
            placeholder='Número de semanas'
            size='large'
            disabled={disableNumberOfWeeks}
            onChange={e => onWeeksChange(e.target.value)}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={8}>
        <Form.Item name='disciplines' label='Disciplinas' rules={rules.disciplines}>
          <DisciplineSelect
            disabled={!amountOfWeeks}
            onChange={onDisciplinesChange}
            loading={loading}
            name='disciplines'
            mode='multiple'
            placeholder='Selecione as disciplinas'
            size='large'
          />
        </Form.Item>
      </Col>

      <Col xs={24} md={8}>
        <Form.Item name='questionsCourse' label='Tipo de Cronograma' rules={rules.disciplines}>
          <StudyScheduleSelect />
        </Form.Item>
      </Col>
    </Row>
    <Row align='end'>
      <Col xs={24} sm={10} lg={4}>
        <Button
          size='large'
          loading={isSubmitting}
          block type='primary'
          onClick={onSubmit}
        >
          Salvar
        </Button>
      </Col>
    </Row>

  </>
)

StudyListForm.propTypes = {
  onWeeksChange: t.func.isRequired,
  onDisciplinesChange: t.func.isRequired,
  amountOfWeeks: t.number,
  loading: t.bool.isRequired,
  isSubmitting: t.bool.isRequired,
  disableNumberOfWeeks: t.bool,
  onSubmit: t.func.isRequired,
}

const rules = {
  name: [{
    required,
    pattern: /^[\w -?!@¨´|{}~A-zÀ-ÿ]{0,100}$/,
    message: 'No máximo 100 caracteres.',
  }],
  disciplines: [required],
  amountOfWeeks: [required],
}

export { StudyListForm }
