import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useClasslistSelect } from './use-class-list-select'

const ClassListSelect = ({ disciplineId, classGroupId, ...antdProps }) => {
  const { classList } = useClasslistSelect({ disciplineId, classGroupId })

  return (
    <Select
      {...antdProps}
      showSearch
      emptyText='Nenhuma aula encontrada'
      allowclear
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      allowClear
    >
      {classList.map(classList => (
        <Option key={classList.code} value={classList.code}>
          {classList.code} - {classList.title}
        </Option>
      ))}
    </Select>
  )
}

ClassListSelect.propTypes = {
  disciplineId: t.string,
  classGroupId: t.string,

}

export { ClassListSelect }
