import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useDisciplinesFetcher } from './use-disciplines-fetcher'

const DisciplineSelect = ({
  loading = false,
  valueName = 'id',
  onFetchCompleted,
  getValue,
  onSelect,
  ...antdProps
}) => {
  const { disciplines, loading: isFetching } = useDisciplinesFetcher(
    onFetchCompleted
  )

  return (
    <Select
      {...antdProps}
      render='true'
      defaultActiveFirstOption
      loading={isFetching || loading}
      emptyText='Nenhuma disciplina encontrada'
      onSelect={onSelect}
      allowClear
    >
      {disciplines.map((discipline) => (
        <Option value={getValue ? getValue(discipline) : discipline[valueName]} key={discipline[valueName]}>
          {discipline.name}
        </Option>
      ))}
    </Select>
  )
}

DisciplineSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  getValue: t.func,
  onFetchCompleted: t.func,
  onSelect: t.func,
}

export { DisciplineSelect }
