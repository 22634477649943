import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { Tooltip } from 'antd'
import { spacing } from 'resources'

const ActionItem = ({ label, component: Component = ButtonContainer, icon: Icon, onClick = () => { }, ...props }) => (
  <Component
    {...props}
    onClick={e => {
      e.stopPropagation()
      onClick && onClick(e)
    }}
  >
    <Tooltip align='center' title={label}>
      <Icon />
    </Tooltip>
  </Component>
)

const ButtonContainer = styled.button`
  && {
    border: 0;
    background: 0;
    outline: 0;
    display: flex;
    align-items: center;
    padding: ${spacing(1)} 0;
    cursor: pointer;
  }
`

ActionItem.propTypes = {
  label: t.string.isRequired,
  icon: t.object.isRequired,
  component: t.object,
  onClick: t.func,
}

export { ActionItem }
