export const required = {
  required: true,
  message: 'Este campo é obrigatório',
}

export const cep = () => ({
  validator: async (_, value) => {
    if (value) {
      const zipcode = value.replace(/\D/g, '')

      if (zipcode.length === 8) {
        const response = await fetch(`https://viacep.com.br/ws/${value}/json/`)
        const json = await response.json()

        return json.cep ? Promise.resolve() : Promise.reject(new Error('CEP inválido'))
      } else {
        return Promise.reject(new Error('CEP inválido'))
      }
    }
  },
})

export const email = {
  type: 'email',
  message: 'E-mail inválido',
}

export const minValue = (min, message) => ({
  validator: (_, value) => value >= min ? Promise.resolve() : Promise.reject(new Error(message || `O valor minímo deste campo é ${min}`)),
})
