import { onError } from '@apollo/client/link/error'
import { removeToken } from 'resources/auth/store'

import { isNotAuthenticated } from './commons'

const hasAuthError = (errors = []) => errors.find(isNotAuthenticated)

// export const validateAuthenticationError = onError(({ graphQLErrors, errors, forward, operation, context }) => {
//   if (hasAuthError(graphQLErrors)) {
//     removeToken()
//       .then(() => {
//         window.location.reload()
//       })
//   }

//   return forward(operation)
// })

export const validateAuthenticationError = onError(({ graphQLErrors }) => {
  if (hasAuthError(graphQLErrors)) {
    removeToken()
      .then(() => {
        window.location.reload()
      })
  }
})
