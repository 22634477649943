import React from 'react'
import styled from 'styled-components'
import { Select, Option } from 'ui'
import { Typography, Tag } from 'antd'
import { spacing } from 'resources'

import { useVideosFetcher } from './use-videos-fecher'

const VideoSelect = props => {
  const { videos, loading } = useVideosFetcher()

  return (
    <Select
      {...props}
      loading={loading}
      emptyText='Nenhum vídeo encontrado'
      optionLabelProp='label'
    >
      {videos.map(({ id, title, time, thumbnail }) => (
        <Option value={id} key={id} label={title}>
          <OptionContent>
            <Thumbnail role='image' alt='Thumbnail do Vídeo' src={thumbnail} />
            <div>
              <Title strong>{title}</Title>
              <Tag color='blue'>{time}</Tag>
            </div>
          </OptionContent>
        </Option>
      ))}
    </Select>
  )
}

const OptionContent = styled.div`
  display: flex;
  padding: 12px 0;
`

const Thumbnail = styled.img`
  width: 72px;
  margin-right: ${spacing(2)};
`

const Title = styled(Typography.Title)`
  && {
    margin: 0 0 ${spacing(1)} 0;
    font-size: 1rem;
  }
`

export { VideoSelect }
