import { gql, useQuery } from '@apollo/client'
import { useCallback } from 'react'

const usePlansFetcher = (
  onFetchCompleted
) => {
  const onCompleted = useCallback(values => {
    onFetchCompleted && onFetchCompleted(values.allActivePlans)
  }, [onFetchCompleted])

  const { data, loading } = useQuery(PLANS_QUERY, {
    fetchPolicy: 'no-cache',
    nodeName: 'allActivePlans',
    onCompleted,
  })

  return {
    plans: data?.allActivePlans || [],
    loading,
  }
}

const PLANS_QUERY = gql`
  {
    allActivePlans {
      id
      name
    }
  }
`

export { usePlansFetcher }
