import React from 'react'
import styled from 'styled-components'
import { Select, Option } from 'ui'

const colors = [
  {
    value: '#f36',
    label: 'Rosa',
  },
]

export const ColorsSelect = antdProps => (
  <Select {...antdProps} optionLabelProp='label' emptyText='Nenhuma cor encontrada'>
    {colors.map(color => (
      <Option key={color.value} value={color.value} label={color.label}>
        <Color color={color.value}>
          {color.label}
        </Color>
      </Option>
    ))}
  </Select>
)

const Color = styled.div`
  padding-left: 2rem;
  position: relative;

  &:before {
    position: absolute;
    content: ' ';
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 2px;
    background-color: ${props => props.color};
    left: 0;
  }
`
