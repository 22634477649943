import { curry, filter, addIndex, reduce as rReduce, map } from 'ramda'

export const makeArray = value => Array(+value).fill()

export const reduce = addIndex(rReduce)

export const stopPropagation = event => {
  event.stopPropagation()
  return event
}

export const preventDefault = event => {
  event.preventDefault()
  return event
}

export const filterI = addIndex(filter)
export const mapI = addIndex(map)

export const conditionalMap = curry((test, left, right, items) => items.map((value, index) => test(value, index) ? left(value) : right(value)))

export const indexEq = curry((value, _, index) => value === index)
