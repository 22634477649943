import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import oopsIconUrl from 'assets/oops.svg'
import offlineIconUrl from 'assets/offline.svg'
import t from 'prop-types'

import { Typography } from 'antd'
import { spacing } from 'resources'

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hasError: false,
      message: '',
      image: '',
    }
  }

  registerNetworkListener = () => {
    window.addEventListener('online', this.clearErrors)
    window.addEventListener('offline', this.showNetworkError)
  }

  clearErrors = () => {
    this.setState(emptyError)
  }

  showNetworkError = () => {
    this.setState(networkError)
  }

  componentDidMount () {
    this.registerNetworkListener()
  }

  componentWillUnmount () {
    window.removeEventListener('online', this.clearErrors)
    window.removeEventListener('offline', this.showNetworkError)
  }

  componentDidUpdate (previous) {
    const { pathname: previousPath } = previous.location
    const { pathname } = this.props.location

    if (pathname !== previousPath) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(emptyError)
    }
  }

  static getDerivedStateFromError (error) {
    if (error) { return unexpectedError }

    return emptyError
  }

  render () {
    const { children } = this.props
    const { message, image } = this.state

    if (this.state.hasError) {
      return (
        <Container>
          <Image visible src={image} alt='Imagem de erro inesperado' />

          <Message strong>
            {message}
          </Message>
        </Container>
      )
    }

    return (
      <>
        <Image visible={false} src={offlineIconUrl} alt='Imagem de falha na conexão' />
        {children}
      </>
    )
  }
}

const unexpectedError = {
  hasError: true,
  message: 'Ocorreu um erro inesperado. Tente novamente em alguns instantes.',
  image: oopsIconUrl,
}

const networkError = {
  hasError: true,
  message: 'Parece que você está sem acesso a internet. Verifique sua conexão e tente novamente',
  image: offlineIconUrl,
}

const emptyError = {
  hasError: false,
  message: '',
  image: '',
}

const Container = styled.div`
  padding: ${spacing(2)};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Image = styled.img`
  display: ${p => p.visible ? 'block' : 'none'};
  width: 300px;
  margin-bottom: ${spacing(3)};
`

const Message = styled(Typography.Text)`
  font-size: 22px;
  max-width: 600px;
  text-align: center;
`

ErrorBoundary.propTypes = {
  children: t.node.isRequired,
  location: t.shape({
    pathname: t.string.isRequired,
  }).isRequired,
}

export default withRouter(ErrorBoundary)
