import React from 'react'
import MaskedInput from 'react-text-mask'

export const PhoneInput = props => (
  <MaskedInput
    mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    className='ant-input ant-input-lg'
    {...props}
  />
)
