export const CONTEXTS = {
  SLUGS: {
    DEFAULT: 'banco-de-questoes',
    BANCO_DE_QUESTOES: 'banco-de-questoes',
    SIMULADAO_ENEM: 'simuladao-enem',
  },
  IDs: {
    DEFAULT: 1,
    BANCO_DE_QUESTOES: 1,
    SIMULADAO_ENEM: 2,
  },
}
