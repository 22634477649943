import styled from 'styled-components'
import { getPrimaryVariant, getColorVariant } from 'resources'
import { Upload, Typography } from 'antd'

const Text = styled(Typography.Text)`
  && {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: ${getColorVariant('gray', 'main')};
    letter-spacing: -0.02em;
    transition: 200ms;
  }
`

const Uploader = styled(Upload.Dragger)`
  && {
    transition: border-color 200ms;
    padding: 0.75rem;

    &.ant-upload.ant-upload-drag {
      display: ${(props) => (props.visible ? 'block' : 'none')};
      border: 2px dashed ${getColorVariant('gray', 'light')};
      background: #fff;

      &:hover {
        border-color: ${getPrimaryVariant('main')};
        ${Text} {
          color: ${getPrimaryVariant('main')};
        }
      }
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: column wrap;

  .anticon {
    color: ${getPrimaryVariant('main')};
    font-size: 2.5rem;
    align-self: center;
  }
`

const File = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  background: #e5ecfe;
  position: relative;

  > .anticon {
    color: ${getPrimaryVariant('main')};
    font-size: 2rem;
    margin-right: 1.25rem;
  }
`

const Label = styled(Typography.Text)`
  color: ${getPrimaryVariant('main')};
  font-weight: 500;
  letter-spacing: -0.02em;
  border-radius: 2px;
`

const RemoveButton = styled.button`
  border: 0;
  background: 0;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 1rem;
  outline: none;
  cursor: pointer;
  .anticon {
    color: ${getColorVariant('danger', 'main')};
  }
`

export { Wrapper, Uploader, File, RemoveButton, Label, Text }
