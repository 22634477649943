import React from 'react'
import t from 'prop-types'

import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'resources'

import { theme } from './resources/theme'
import { ConfigProvider } from 'antd'

const providers = [
  {
    component: ThemeProvider,
    props: { theme },
  },

  { component: BrowserRouter },

  { component: AuthProvider },

  {
    component: ConfigProvider,
    props: { getPopupContainer: () => document.querySelector('#portal-root') },
  },
]

function Providers({ children }) {
  return providers.reduceRight((child, provider) => {
    return <provider.component {...provider.props} children={child} />
  }, children)
}

Providers.propTypes = {
  children: t.node.isRequired,
}

export default Providers
