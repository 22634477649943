import React from 'react'
import { Select, Option } from 'ui'

const ActiveSelect = props => (
  <Select {...props}>
    <Option value='true'>Ativo</Option>
    <Option value='false'>Inativo</Option>
  </Select>
)

const HiddenSelect = props => (
  <Select {...props}>
    <Option value='true'>Sim</Option>
    <Option value='false'>Não</Option>
  </Select>
)

export { ActiveSelect, HiddenSelect }
