import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

import { useInstitutionsFetcher } from './use-institutions-fetcher'

const InstitutionsSelect = ({ ...antdProps }) => {
  const { institutions, isFetching } = useInstitutionsFetcher()

  return (
    <Select
      {...antdProps}
      data-testid='institutions-select'
      loading={isFetching}
      emptyText='Nenhuma instituição encontrada'
    >
      {institutions.map(({ id, name }) => (
        <Option value={id} key={id}>{name}</Option>
      ))}
    </Select>
  )
}
InstitutionsSelect.propTypes = {
  loading: t.bool,
}

export { InstitutionsSelect }
