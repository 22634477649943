import React from 'react'
import styled, { css } from 'styled-components'
import t from 'prop-types'
import { Table as AntdTable } from 'antd'

const Table = ({ pagination, onRowClick = () => { }, fontSize = false, ...props }) => (
  <StyledTable
    {...props}
    rowClassName='ant-design-custom-row'
    onRow={(record) => ({
      onClick: _ => onRowClick(record),
    })}
    pagination={makePagination(pagination)}
    fontSize={fontSize}
  />
)

const makePagination = pagination => pagination ? ({
  total: pagination.total,
  pageSize: pagination.perPage,
  current: pagination.page,
  locale: { items_per_page: '' },
  onChange: page => { pagination.setPage(page) },
  onShowSizeChange: (_, newSize) => { pagination.setPerPage(newSize) },
  showTotal: (total, range) => `Exibindo ${range[0]}-${range[1]} de ${total} itens`,
}) : false

const StyledTable = styled(AntdTable)`
  && {
    .ant-pagination-options {
      display: none;
    }
    .ant-design-custom-row {
      cursor: pointer;
      word-break: break-all;
    }
  }
  ${({ fontSize }) => fontSize === true && css`
  .ant-table-container {
    font-size: 0.8rem;
  }`}
`

Table.propTypes = {
  onRowClick: t.func,
  pagination: t.shape({
    perPage: t.number.isRequired,
    page: t.number.isRequired,
    setPage: t.func.isRequired,
    total: t.number.isRequired,
    setPerPage: t.func.isRequired,
  }),
  fontSize: t.bool,
}

export { Table }
