import { useState } from 'react'

const useDraggableList = initialState => {
  const [items, setItems] = useState(initialState)

  const reorder = result => {
    if (!result.destination) return

    const { source, destination } = result
    const updated = Array.from(items)
    const [removed] = updated.splice(source.index, 1)
    updated.splice(destination.index, 0, removed)

    setItems(updated)
  }

  const append = item => setItems(items => [...items, item])

  const removeAll = () => setItems([])

  const remove = index => {
    const updatedItems = items.filter((_, currentIndex) => currentIndex !== index)
    setItems(updatedItems)
  }

  return [
    items,
    {
      reorder,
      remove,
      append,
      removeAll,
      setList: setItems,
    },
  ]
}

export { useDraggableList }
