import React from 'react'

import t from 'prop-types'
import { EmptyState } from 'ui'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const EmptyMessage = ({ onAddClick, description = 'Ainda não há itens' }) => (
  <EmptyState
    imageSize={70}
    description={description}
  >
    {onAddClick && (
      <Button
        onClick={onAddClick}
        icon={<PlusOutlined />}
        type='primary'
      >
        Adicionar item
      </Button>
    )}
  </EmptyState>
)

EmptyMessage.propTypes = {
  onAddClick: t.func,
  description: t.string,
}

export { EmptyMessage }
