import React from 'react'
import ReactDOM from 'react-dom'
import Root from './root'
import * as Sentry from '@sentry/react'

import packageJson from '../package.json'

import 'antd/es/modal/style'
import 'antd/es/slider/style'

const { REACT_APP_SENTRY_KEY } = process.env

Sentry.init({
  dsn: REACT_APP_SENTRY_KEY,
  release: packageJson.version,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
})

ReactDOM.render(<Root />, document.getElementById('root'))
