import { gql, useQuery } from '@apollo/client'
import { useCallback } from 'react'

const useVoucherSelect = (
  onFetchCompleted
) => {
  const onCompleted = useCallback(values => {
    onFetchCompleted && onFetchCompleted(values.vouchersTemplates.nodes)
  }, [onFetchCompleted])

  const { data, loading } = useQuery(VOUCHER_TEMPLATE_QUERY, {
    fetchPolicy: 'no-cache',
    nodeName: 'vouchersTemplates',
    onCompleted,
  })

  return {
    vouchersTemplates: data?.vouchersTemplates.nodes || [],
    loading,
  }
}

const VOUCHER_TEMPLATE_QUERY = gql`
  {
    vouchersTemplates {
     nodes {
        id
        title
        }
    }
  }
`

export { useVoucherSelect }
