import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { Avatar as AntdAvatar } from 'antd'
import { getPrimaryVariant } from 'resources'

const Avatar = ({ size = 36, photo, name }) => (
  <StyledAvatar src={photo} size={size}>{getFirstLetter(name)}</StyledAvatar>
)

const getFirstLetter = name => name && name.substring(0, 1)

export const StyledAvatar = styled(AntdAvatar)`
  background-color: ${getPrimaryVariant('main')};

  > span {
    font-size: ${props => props.size * 0.3}px;
  }
`

Avatar.propTypes = {
  size: t.number,
  photo: t.string,
  name: t.string,
}

export { Avatar }
