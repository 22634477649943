import { setContext } from '@apollo/client/link/context'
import { getToken } from 'resources/auth/store'

const isPublicOperation = operationName => /\blogin\b/i.test(operationName) || /RecoverPassword/.test(operationName) || /NewPassword/.test(operationName) || /PartnersCouponsReport/i.test(operationName)

const authLink = setContext(async ({ operationName }, { headers }) => {
  if (isPublicOperation(operationName)) return { headers }

  const { token } = await getToken()

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
})

export { authLink }
