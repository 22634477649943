import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useClasses } from './use-classes'
import { Spinner } from 'ui/spinner'

const ClassesSelect = ({ valueName = 'id', ...antdProps }) => {
  const { classes, isSearching, isFetching, handleSearch } = useClasses()

  return (
    <Select
      {...antdProps}
      showSearch
      onSearch={handleSearch}
      loading={isSearching || isFetching}
      emptyText='Nenhuma aula encontrada'
      filterOption={false}
      notFoundContent={
        isSearching
          ? <Spinner />
          : null
      }
    >
      {classes.map(clazz => (
        <Option key={clazz[valueName]} value={clazz[valueName]}>
          {clazz.code} - {clazz.title}
        </Option>
      ))}
    </Select>
  )
}

ClassesSelect.propTypes = {
  valueName: t.oneOf(['id', 'slug']),
}

export { ClassesSelect }
