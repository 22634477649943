import React, { useMemo } from 'react'
import t from 'prop-types'
import { PlusOutlined } from '@ant-design/icons'
import { getPrimaryVariant } from 'resources'
import { Button, Col, Row, Typography } from 'antd'
import { Form, VideoInput, EmptyMessage } from 'ui'
import styled from 'styled-components'

const VideoList = ({ amount = '0', hasAdd = false, hasTitle = true }) => {
  const list = useMemo(() => listOfAmount(+amount), [amount])

  return (
    <Wrapper>
      {hasAdd && (
        <Row gutter={[0, 12]} justify='end'>
          <Col xs={24} sm={24} md={6}>
            <Button prefix={<PlusOutlined />} block type='ghost'>
              Adicionar vídeo
            </Button>
          </Col>
        </Row>
      )}

      {+amount > 0 && hasTitle && <Title level={4}>Vídeos</Title>}

      {+amount > 0 &&
        list.map((_, index) => (
          <Item key={index}>
            <Position>{index + 1}º</Position>
            <FormItem name={`video-${index}`}>
              <VideoInput placeholder=' ID do Vídeo' size='large' />
            </FormItem>
          </Item>
        ))}

      {+amount <= 0 && (
        <EmptyMessage description='Informe a quantidade de vídeos' />
      )}
    </Wrapper>
  )
}

const listOfAmount = (amount) => (amount > 0 ? Array(amount).fill() : [])

const Wrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  // background: #E5ECFE;
  padding: 1.5rem 2rem;
  max-height: 20rem;
  overflow: auto;
`

const Item = styled.li`
  margin-bottom: 0.5rem;
  display: flex;
  align-items: flex-start;
  width: 100%;
`

const Position = styled(Typography.Text)`
  && {
    color: ${getPrimaryVariant('main')};
    font-size: 1.25rem;
    font-weight: 600;
    margin-right: 1rem;
  }
`

const FormItem = styled(Form.Item)`
  width: 100%;
`

const Title = styled(Typography.Title)`
  && {
    color: ${getPrimaryVariant('main')};
    margin-bottom: 1.5rem;
  }
`

VideoList.propTypes = {
  amount: t.string.isRequired,
  hasTitle: t.bool,
  hasAdd: t.bool,
}

export { VideoList }
