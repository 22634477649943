import styled from 'styled-components'
import { Typography, Tabs as AntdTabs } from 'antd'
import { getColorVariant, getPrimaryVariant } from 'resources'
import { pipe } from 'ramda'
import { transparentize } from 'polished'

export const WeekWrapper = styled.div`
  border-radius: 4px;
  margin-right: 1rem;
  padding: 1rem;
  background: #E5ECFE;
  display: flex;
  min-width: 21rem;
  max-width: 21rem;
  flex-flow: column wrap;
  /* overflow: auto ; */
`

export const ListContainer = styled.div`
  height: auto;
  /* overflow-y: hidden; */
  padding: 0.75rem 0.5rem;
  box-sizing:border-box;
  display: flex;
`

export const WeekHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled(Typography.Text)`
  color: ${getPrimaryVariant('main')};
  font-weight: 600;
`

export const List = styled.ul`
  list-style: none;
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  flex: 3;
  display: ${props => props.isEmpty ? 'flex' : 'block'};
  justify-content: center;
  /* overflow: auto; */
`

export const Position = styled.span`
  color: ${getColorVariant('primary', 'main')};
  font-weight: 600;
  font-size: 0.85rem;
  margin-right: 1rem;
  margin-top: 0.15rem;
`

export const Type = styled(Typography.Text)`
  && {
    font-size: 0.75rem;
    font-weight: 600;
    color: #c1c1c1;
  }
`

export const ItemTitle = styled(Typography.Title)`
  &&& {
    word-break: break-all;
    font-size: 1rem;
    margin: 0;
  }
`

export const RemoveItem = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0.45rem;
  top: 0.25rem;
  opacity: 0;

  .anticon {
    color: ${getColorVariant('danger', 'main')};
  }
`
export const Item = styled.li`
  background: #fff;
  display: flex;
  border-radius: 2px;
  position: relative;
  width: 100%;
  padding: 0.85rem;
  margin-bottom: 0.5rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

  &:hover > ${RemoveItem} {
    opacity: 1;
    transition: 200ms;
  }
`

export const AddItem = styled.button`
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: ${getColorVariant('primary', 'main')};
  outline: none;
  cursor: pointer;
  padding: 0.75rem 0;
  border-radius: 2px;
  width: 100%;

  &:hover {
    transition: 200ms;
    background: ${pipe(
     getColorVariant('primary', 'main'),
     transparentize(0.9)
    )};
  }

  .anticon {
    color: ${getColorVariant('primary', 'main')};
    margin-right: 1rem;
  }
`

export const Tabs = styled(AntdTabs)`
  &&&&& {
    .ant-tabs-top-bar {
      border: none;
      margin-bottom: 1.5rem;
    }

    .ant-tabs-tab {
      margin-right: 0.85rem;
      border: solid 1px ${getPrimaryVariant('main')};
      border-radius: 1000px;
      padding: 0.5rem 0.85rem;
      color: ${getPrimaryVariant('main')};
    }

    .ant-tabs-tab-active {
      background: ${getPrimaryVariant('main')};
      color: #fff;
    }

    .ant-tabs-nav-scroll {
      padding: .25rem;
    }

    .ant-tabs-ink-bar {
      opacity: 0;
    }

  }
`

export const ItemDivider = styled.div`
  margin: 1rem;
`
export const ItemDivider2 = styled.div`
  border-bottom: solid 1px;
`

export const WeeksContainer = styled.ul`
  margin: 0;
  padding: 0.5rem 0;
  display: flex;
  ${({ scroll }) => scroll && 'overflow: auto;'}

`

export const EmptyStateContainer = styled.div`
  min-height: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: 8px;
  border: 8px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 300px;
`
export const DropZone = styled.div`
  min-height: 420px;
  padding-bottom: 8px;
  width: 100%;
`

export const ScrollContainer = styled.div`
  /* overflow-x: hidden;
  overflow-y: auto; */
  /* max-height: 500px; */
`

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return '#FFEBE6'
  }
  if (isDraggingFrom) {
    return '#E6FCFF'
  }
  return '#EBECF0'
}

export const getBorderColor = (isDragging, authorColors) =>
  isDragging ? '#ccc' : 'transparent'

export function getStyle (provided, style) {
  if (!style) {
    return provided.draggableProps.style
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  }
}

export const Container = styled.a`
  border-radius: 2px;
  border: 2px solid transparent;
  border-color: ${(props) => getBorderColor(props.isDragging, props.colors)};
  background-color: ${(props) =>
    getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) => (isDragging ? '2px 2px 1px #A5ADBA' : 'none')};
  box-sizing: border-box;
  padding: 8px;
  min-height: 40;
  margin-bottom: 8px;
  user-select: none;

  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: #000;
    box-shadow: none;
  }

  display: flex;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

Tabs.Pane = AntdTabs.TabPane
