import { useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { getNodes } from 'resources'

export const SUBJECTS_QUERY = gql`
  query GetSubjects($filter:FilterSubjectsInput!){
    subjects (filter: $filter){
     nodes {
        id
       slug
       name
      }
    }
  }
`

const useSubjectsFetcher = ({ onlyParents, parentSlug, disciplineSlug, parentId, disciplineId }) => {
  const [fetchSubjects, { data, loading }] = useLazyQuery(SUBJECTS_QUERY, { fetchPolicy: 'no-cache' })

  useEffect(() => {
    if (disciplineSlug || parentId || disciplineId || parentSlug) {
      fetchSubjects({
        variables: {
          filter: {
            onlyParents,
            parentSlug,
            parentId,
            disciplineSlug,
            disciplineId,
            showAll: true,
          },
        },
      })
    }
  }, [disciplineSlug, onlyParents, parentSlug, fetchSubjects, parentId, disciplineId])
  useEffect(() => {
  }, [parentSlug])

  return {
    subjects: getNodes('subjects', data),
    isFetching: loading,
  }
}

export { useSubjectsFetcher }
