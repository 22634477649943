import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { Spinner } from 'ui'

const SpinnerOverlay = ({ mode = 'absolute' }) => (
  <Overlay mode={mode}>
    <Spinner />
  </Overlay>
)

SpinnerOverlay.propTypes = {
  mode: t.string,
}

const Overlay = styled.div`
  position: ${p => p.mode};
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`

export { SpinnerOverlay }
