import { gql } from '@apollo/client'
import { useCallback } from 'react'
import { usePaginatedQuery } from 'resources'

const useCouponsSelect = (onFetchCompleted) => {
  const onCompleted = useCallback(
    (values) => {
      onFetchCompleted && onFetchCompleted(values.coupons.nodes)
    },
    [onFetchCompleted]
  )

  const { coupons, loading } = usePaginatedQuery(COUPONS_QUERY, {
    nodeName: 'coupons',
    fetchPolicy: 'no-cache',
    perPage: 100,
    page: 1,
    onCompleted,
  })

  return {
    coupons: coupons || [],
    loading,
  }
}

const COUPONS_QUERY = gql`
  query GetCoupons($pagination: PaginationInput) {
    coupons(pagination: $pagination) {
       nodes {
        id
        title
      }
     }
  }
`

export { useCouponsSelect }
