import React from 'react'
import t from 'prop-types'
import { DeleteFilled } from '@ant-design/icons'
import { ResourceTypes } from 'resources'

import { Position, Type, ItemTitle, ItemDivider, RemoveItem, Item } from './components'
import { preventDefault } from 'resources/composable'
import { pipe } from 'ramda'

const ResourceItem = ({ onRemove, type, title, id }) => {
  return (
    <Item>
      <Position>{`${id + 1}º`}</Position>

      <div>
        <Type>{aliases[type]}</Type>
        <ItemTitle>{title}</ItemTitle>
      </div>

      <RemoveItem onClick={pipe(preventDefault, onRemove)}>
        <DeleteFilled />
      </RemoveItem>
    </Item>
  )
}

const ParentSubjectItem = ({ onRemove, title, id, parentSubject }) => (
  <Item>
    <Position>{`${id + 1}º`}</Position>

    <div>
      <Type>Assunto primário</Type>
      <ItemTitle>{parentSubject.name}</ItemTitle>
      <ItemDivider />
      <Type>Assunto secundário</Type>
      <ItemTitle>{title}</ItemTitle>
    </div>

    <RemoveItem onClick={pipe(preventDefault, onRemove)}>
      <DeleteFilled />
    </RemoveItem>
  </Item>
)

const VideoGroupItem = ({ onRemove, videos, id, title }) => {
  return (
    // <Draggable index={id} key={id} draggableId={`week-item-${id}`}>
    //   {(provided, infos) => (
    <Item>
      <Position>{`${id + 1}º`}</Position>

      <div>
        <Type>Grupo de vídeo</Type>
        <ItemDivider />
        <Type>Título</Type>
        <ItemTitle>{title}</ItemTitle>
        <ItemDivider />
        <Type>Vídeos</Type>
        {videos.map((row) => (
          <>
            <ItemTitle>- {row.title}</ItemTitle>
          </>
        ))}
      </div>

      <RemoveItem onClick={pipe(preventDefault, onRemove)}>
        <DeleteFilled />
      </RemoveItem>
    </Item>
    //   )}
    // </Draggable>
  )
}

const PdfsItem = ({ onRemove, title, id, discipline }) => {
  return (
    <Item>
      <Position>{`${id + 1}º`}</Position>

      <div>
        <Type>Disciplina</Type>
        <ItemTitle>{discipline}</ItemTitle>
        <ItemDivider />
        <Type>PDF</Type>
        <ItemTitle>{title}</ItemTitle>
      </div>

      <RemoveItem onClick={pipe(preventDefault, onRemove)}>
        <DeleteFilled />
      </RemoveItem>

    </Item>
  )
}

const aliases = {
  [ResourceTypes.SIMULATED]: 'Simulado',
  [ResourceTypes.QUESTIONS_SUBJECT]: 'Assunto primário',
  [ResourceTypes.CLASS]: 'Aula',
  [ResourceTypes.PDFS]: 'Resumo gráfico',
}

ResourceItem.propTypes = {
  type: t.string.isRequired,
  id: t.number.isRequired,
  title: t.string.isRequired,
  onRemove: t.func.isRequired,
}

ParentSubjectItem.propTypes = {
  id: t.number.isRequired,
  title: t.string.isRequired,
  parentSubject: t.object.isRequired,
  onRemove: t.func.isRequired,
}

PdfsItem.propTypes = {
  id: t.number.isRequired,
  title: t.string.isRequired,
  discipline: t.oneOfType([
    t.object,
    t.string,
  ]),
  onRemove: t.func.isRequired,
}

VideoGroupItem.propTypes = {
  id: t.number.isRequired,
  videos: t.array,
  onRemove: t.func.isRequired,
  title: t.string,
}

export { ResourceItem, ParentSubjectItem, PdfsItem, VideoGroupItem }
