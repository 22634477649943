import React from 'react'
import { Modal } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

export const openVoucherDialog = ({ voucher, onConfirm }) => {
  const title = 'Sucesso!'
  const content = `Voucher ${voucher} gerado com sucesso!`

  confirm({
    title,
    content,
    icon: <CheckCircleOutlined style={{ color: 'green' }} />,
    onOk: () => onConfirm(),
    okText: 'Ok',
    okType: 'success',
    cancelButtonProps: { style: { display: 'none' } },
  })
}
