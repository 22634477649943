import { gql, useQuery } from '@apollo/client'
import { getNodes } from 'resources'

export const TEACHERS_QUERY = gql`
{
  teachers {
    nodes {
      id
      name
    }
  }
}
`

const useTeachersFetcher = () => {
  const { data, loading } = useQuery(TEACHERS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {},
  })

  return {
    teachers: getNodes('teachers', data),
    isFetching: loading,
  }
}

export { useTeachersFetcher }
