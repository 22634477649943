import { generateMedia } from 'styled-media-query'

const media = generateMedia({
  phone: '580px',
  tablet: '900px',
  smallDesktop: '1100px',
  mediumDesktop: '1440px',
  largeDesktop: '1600px',
})

export { media }
