import React, { useEffect, useRef } from 'react'
import t from 'prop-types'
import { Editor as TinyEditor } from '@tinymce/tinymce-react'
import { useEditor } from './use-editor'

const Editor = ({
  onInit,
  onChange,
  onBlur,
  onFocus,
  initialData = '',
  ...rest
}) => {
  const { handleFileUpload } = useEditor()
  const editorRef = useRef(null)

  /**
   * Workaround to force the editor to update the initial value
   */
  useEffect(() => {
    if (
      editorRef.current &&
      editorRef.current.getContent().length === 0 &&
      (rest.value || initialData)
    ) {
      editorRef.current.setContent(rest.value || initialData)
    }
  }, [rest.value, initialData])

  return (
    <TinyEditor
      onInit={(_evt, editor) => (editorRef.current = editor)}
      onEditorChange={onChange}
      initialValue={rest.value || initialData}
      init={{
        height: 200,
        menubar: false,
        plugins: ['image', 'link'],
        external_plugins: { tiny_mce_wiris: wirisPluginUrl },
        toolbar,
        // É necessário passar um valor, para exibir a "tab" de upload
        images_upload_url: 'upload-url',
        images_upload_handler: handleFileUpload,
        image_file_types: 'jpeg,jpg,png',
        language: 'pt_BR',
      }}
    />
  )
}
const URL = window.location.origin
const wirisPluginUrl = `${URL}/tinymce/tinymce-wiris-plugin.min.js`

const toolbar = [
  'bold',
  'italic',
  'underline',
  'strikeThrough',
  'fontsizeselect',
  'forecolor',
  '|',
  'link',
  'image',
  '|',
  'undo',
  'redo',
  '|',
  'alignleft',
  'aligncenter',
  'alignright',
  'alignjustify',
  '|',
  'tiny_mce_wiris_formulaEditor',
].join(' ')

Editor.propTypes = {
  onInit: t.func,
  onChange: t.func,
  onBlur: t.func,
  onFocus: t.func,
  initialData: t.string,
}

export { Editor }
