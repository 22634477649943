import { useQuery, gql } from '@apollo/client'
import { getNodes } from 'resources'

const useClasslistSelect = ({ disciplineId, classGroupId }) => {
  const { data } = useQuery(CLASS_LIST_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: { disciplineId: disciplineId || null, classGroupId: classGroupId || null },
      pagination: {},
    },
  })

  return {
    classList: getNodes('classes', data),
  }
}

const CLASS_LIST_QUERY = gql`
    query GetClasses($pagination: PaginationInput, $filter: FilterClassesInput){
    classes(pagination: $pagination, filter: $filter){
      nodes {
        id
        code
        title
      }
    }
  }
`

export { useClasslistSelect }
