import { gql, useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { getNodes } from 'resources'

const useDisciplinesFetcher = (
  onFetchCompleted
) => {
  const onCompleted = useCallback(values => {
    onFetchCompleted && onFetchCompleted(values.disciplines.nodes)
  }, [onFetchCompleted])

  const { data, loading } = useQuery(DISCIPLINES_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted,
  })

  return {
    disciplines: getNodes('disciplines', data),
    loading,
  }
}

const DISCIPLINES_QUERY = gql`
  {
    disciplines(filter: { showAll: true }){
      nodes {
        id
        slug
        name
      }
    }
  }
`

export { useDisciplinesFetcher }
