export const ResourceTypes = {
  CLASS: 'CLASS',
  QUESTIONS_SUBJECT: 'QUESTIONS_SUBJECT',
  SIMULATED: 'SIMULATED',
  PDFS: 'PDFS',
  VIDEO_GROUP: 'VIDEO_GROUP',
}

export const pdfsTypes = {
  GRAPHIC_SUMMARIES: 'Resumo gráfico',
  QUESTIONS: 'Questões',
}
