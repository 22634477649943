import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

const useClassTypeSelect = () => {
  const type = [
    {
      id: 0,
      title: 'Aula regular',
    },
    {
      id: 1,
      title: 'Revisão de playlist',
    },
  ]

  return {
    type,
  }
}

const ClassTypeSelect = ({
  loading = false,
  valueName = 'id',
  onFetchCompleted,
  getValue,
  onSelect,
  ...antdProps
}) => {
  const { type } = useClassTypeSelect(
    onFetchCompleted
  )

  return (
    <Select
      {...antdProps}
      render='true'
      defaultActiveFirstOption
      emptyText='Nenhum tipo encontrado'
      onSelect={onSelect}
      allowClear
    >
      {type.map((type) => (
        <Option value={type.id} key={type[valueName]}>
          {type.title}
        </Option>
      ))}
    </Select>
  )
}

ClassTypeSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  getValue: t.func,
  onFetchCompleted: t.func,
  onSelect: t.func,
}

export { ClassTypeSelect }
