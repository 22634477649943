import React from 'react'

import { Select, Option } from 'ui'

const StudyScheduleSelect = ({
  ...antdProps
}) => {
  return (
    <Select
      {...antdProps}
      size='large'
      placeholder='Selecione o tipo de cronograma'
      allowClear
    >
      <Option value='true'>Curso de Questões</Option>
      <Option value='false'>Planos de Estudo</Option>
    </Select>
  )
}

export { StudyScheduleSelect }
