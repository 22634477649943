import { gql, useQuery } from '@apollo/client'
import { useCallback } from 'react'

const usePlansFetcher = (onFetchCompleted) => {
  const onCompleted = useCallback(
    (values) => {
      onFetchCompleted && onFetchCompleted(values.checkoutPlansConfig.nodes)
    },
    [onFetchCompleted]
  )

  const { data, loading } = useQuery(PLANS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted,
  })

  return {
    plans: data?.checkoutPlansConfig?.nodes || [],
    loading,
  }
}

const PLANS_QUERY = gql`
  {
    checkoutPlansConfig {
      nodes {
        id
        name
      }
    }
  }
`

export { usePlansFetcher }
