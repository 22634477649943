import React from 'react'
import t from 'prop-types'
import { Spin } from 'antd'
import styled from 'styled-components'
import { getSidebarProp, media } from 'resources'

const Spinner = ({ fullWidth = true }) => (
  <Container fullWidth={fullWidth}>
    <Spin size='large' />
  </Container>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${media.greaterThan('smallDesktop')`
    padding-left: ${props => props.fullWidth ? 0 : getSidebarProp('width', props)}px;
  `}
`

Spinner.propTypes = {
  fullWidth: t.bool,
}

export { Spinner }
