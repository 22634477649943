import React from 'react'
import t from 'prop-types'
import { useTheme } from 'styled-components'
import { getSidebarProp, useAuth, getColor } from 'resources'
import { LogoutOutlined } from '@ant-design/icons'
import { Logo } from 'ui'

import { Sider, Drawer, Header, Content, LogoutButton } from './components'

const Sidebar = ({ onClose, visible, children }) => {
  const theme = useTheme()
  const { logout } = useAuth()

  return (
    <>
      <Sider
        theme='dark'
        background={getColor('black', { theme })}
        width={getSidebarProp('width', { theme })}
      >
        <Header>
          <Logo size={200} variant='white' />
        </Header>

        <Content>{children}</Content>
        <LogoutButton onClick={logout}>
          <LogoutOutlined />
          Sair
        </LogoutButton>
      </Sider>

      <Drawer
        placement='left'
        closable={false}
        onClose={onClose}
        open={visible}
      >
        <Header>
          <Logo size={200} variant='white' />
        </Header>

        <Content>{children}</Content>
        <LogoutButton onClick={logout}>
          <LogoutOutlined />
          Sair
        </LogoutButton>
      </Drawer>
    </>
  )
}
Sidebar.propTypes = {
  onClose: t.func.isRequired,
  visible: t.bool.isRequired,
  children: t.node.isRequired,
}

export { Sidebar }
