import React from 'react'
import t from 'prop-types'
import { DeleteFilled, SettingOutlined } from '@ant-design/icons'
import { ResourceTypes, getColorVariant } from 'resources'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import {
  Divider,
  Card,
  Item,
  InfosContainer,
  Position,
  Title,
  Type,
  DeleteButton,
  TimelineButton,
} from './components'
import { Tooltip, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { CREATE_TIMELINE } from 'routes'

const aliases = {
  [ResourceTypes.CLASS]: 'Aula',
  [ResourceTypes.QUESTIONS_SUBJECT]: 'Assunto primário',
  [ResourceTypes.SIMULATED]: 'Simulado',
}

const DraggableContainer = ({ id, position, children, onDelete }) => (
  <Draggable index={position} key={position} draggableId={`${id}-${position}`}>
    {(provided, infos) => (
      <Item ref={provided.innerRef} {...provided.draggableProps}>
        <Card
          dragHandleProps={provided.dragHandleProps}
          isDragging={infos.isDragging}
        >
          <InfosContainer>
            {children}
            <DeleteAction onClick={() => onDelete(position)} />
          </InfosContainer>
        </Card>
      </Item>
    )}
  </Draggable>
)

export const ResourceItem = ({
  videoId,
  slug,
  name,
  type,
  position,
  onDelete,
}) => (
  <DraggableContainer onDelete={onDelete} position={position} id={slug}>
    <Position>{position + 1}º</Position>
    <div>
      <Type>{aliases[type]}</Type>
      <Title>{name}</Title>

      {videoId && (
        <Link to={CREATE_TIMELINE.replace(':id', videoId)}>
          <TimelineButton>
            <Tooltip title='Editar timeline'>
              <SettingOutlined />
            </Tooltip>
          </TimelineButton>
        </Link>
      )}
    </div>
  </DraggableContainer>
)

export const SubjectItem = ({ slug, name, position, onDelete }) => (
  <DraggableContainer onDelete={onDelete} position={position} id={slug}>
    <Position>{position + 1}º</Position>
    <div>
      <Type>Assunto primário</Type>
      <Title>{name}</Title>
    </div>
  </DraggableContainer>
)

export const ParentSubjectItem = ({
  slug,
  name,
  parentName,
  position,
  onDelete,
}) => (
  <DraggableContainer onDelete={onDelete} position={position} id={slug}>
    <Position>{position + 1}º</Position>

    <div>
      <Type>Assunto primário</Type>
      <Title>{parentName}</Title>
    </div>

    <Divider type='vertical' />

    <div>
      <Type>Assunto secundário</Type>
      <Title>{name}</Title>
    </div>
  </DraggableContainer>
)

export const PdfsItem = ({
  onDelete, title, discipline, position, slug,
}) => (
  <DraggableContainer onDelete={onDelete} position={position} id={slug}>
    <Position>{position + 1}º</Position>

    <div>
      <Type>Disciplina</Type>
      <ItemTitle>{discipline}</ItemTitle>
      <ItemDivider />
      <Type>PDF</Type>
      <ItemTitle>{title}</ItemTitle>
    </div>
  </DraggableContainer>
)

const DeleteAction = (props) => (
  <Tooltip title='Remover item'>
    <DeleteButton {...props} type='button'>
      <DeleteFilled />
    </DeleteButton>
  </Tooltip>
)

ResourceItem.propTypes = {
  position: t.number.isRequired,
  name: t.string.isRequired,
  type: t.oneOf(['class', 'question']),
  slug: t.string.isRequired,
  videoId: t.string.isRequired,
  onDelete: t.func.isRequired,
}

SubjectItem.propTypes = {
  position: t.number.isRequired,
  name: t.string.isRequired,
  slug: t.string.isRequired,
  onDelete: t.func.isRequired,
}

ParentSubjectItem.propTypes = {
  position: t.number.isRequired,
  name: t.string.isRequired,
  parentName: t.string.isRequired,
  slug: t.string.isRequired,
  onDelete: t.func.isRequired,
}

DraggableContainer.propTypes = {
  position: t.number.isRequired,
  children: t.node.isRequired,
  id: t.string.isRequired,
  onDelete: t.func.isRequired,
}

PdfsItem.propTypes = {
  title: t.string.isRequired,
  discipline: t.object.isRequired,
  onDelete: t.func.isRequired,
  position: t.number.isRequired,
  slug: t.string.isRequired,
}

export const ItemTitle = styled(Typography.Title)`
  &&& {
    font-size: 1rem;
    margin: 0;
  }
`
export const ItemDivider = styled.div`
  margin: 1rem;
`

export const RemoveItem = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0.85rem;
  top: 0.85rem;
  opacity: 0;

  .anticon {
    color: ${getColorVariant('danger', 'main')};
  }
`

export { Item }
