import { gql, useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { getNodes } from 'resources'

const useContextsFetcher = (onFetchCompleted) => {
  const onCompleted = useCallback(
    (values) => {
      onFetchCompleted && onFetchCompleted(values.contexts.nodes)
    },
    [onFetchCompleted]
  )

  const { data, loading } = useQuery(CONTEXT_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted,
  })

  return {
    contexts: getNodes('contexts', data),
    loading,
  }
}

const CONTEXT_QUERY = gql`
  {
    contexts {
      nodes {
        id
        name
        slug
        allowOptionalDisciplines
        useTRIScore
      }
    }
  }
`

export { useContextsFetcher }
