import styled, { css } from 'styled-components'
import { Menu as AntdMenu } from 'antd'
import { getPrimaryVariant, getColorVariant } from 'resources'

const iconStyles = css`
  font-size: 1.25rem;
`

export const Menu = styled(AntdMenu)`
  && {
    background: ${props => props.background};
  }
`

const Label = styled.span`
  font-size: 1rem;
`

const SubMenu = styled(AntdMenu.SubMenu)`
  && {
    padding: 0.5rem 0;

     .ant-menu-item {
      padding: 1.5rem 2rem;
      span {
        font-size: 0.90rem;
      }
     }

    .subtitle-icon {
      ${iconStyles}
    }

    > ul.ant-menu-sub {
      background: transparent;
    }

     span, > a > span:hover {
      color: ${getColorVariant('gray', 'main')};
    }
  }

  .ant-menu-submenu-selected .subtitle-icon {
    color: ${getPrimaryVariant('main')};
  }

  .anticon {
    margin-right: 1.25rem;
    ${iconStyles}
  }
`

const Item = styled(AntdMenu.Item)`
  && {
    background: transparent;
    padding: 1.75rem 2rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    > a > span {
      color: ${getColorVariant('gray', 'main')};
    }
  }

  &&&.ant-menu-item-selected {
    background: transparent;
    > a > span {
      font-weight: 600;
    }

    > a >span , .anticon {
      color: ${getPrimaryVariant('main')};
    }
  }

  .anticon {
    margin-right: 1.25rem;
    ${iconStyles}
  }
`

export { SubMenu, Item, Label }
