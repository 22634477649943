import { curry } from 'ramda'

export const HOME = '/'
export const LOGIN = '/login'
export const CLASSES = '/classes'
export const PDFS = '/pdfs'
export const SIMULATEDS = '/simulados'
export const SIMULATEDS_STATISTIC = '/estatisticas-simulados'
export const USERS = '/usuarios'
export const EVENTS = '/eventos'
export const QUESTIONS = '/questoes'
export const CLASS_GROUPS = '/grupos-de-aulas'
export const TEACHERS = '/professores'
export const STUDY_PLAN = '/plano-de-estudo'
export const STUDY_LIST = '/lista-de-estudo'
export const NOTIFICATIONS = '/notificacoes'
export const DISCIPLINES = '/materias'
export const ESSAY_THEMES = '/temas-redacao'
export const SUBJECTS = '/assuntos-primarios'
export const SECONDARY_SUBJECTS = '/assuntos-secundários'
export const COMMENTED_TESTS = '/provas-comentadas'
export const STUDENTS = '/alunos'
export const TESTIMONIALS_WALL = '/mural-de-aprovados'
export const AUDIENCES = '/publicos'
export const INSTITUTIONS = '/instituicoes'
export const RECOVER_PASSWORD = '/recuperar-senha'
export const NEW_PASSWORD = '/nova-senha'
export const COURSES = '/cursos'
export const NEW = '/cadastrar'
export const ANNOUNCEMENT = '/banner-plataforma'
export const ANNOUNCEMENT_SITE = '/banner-site'
export const COUPONS = '/cupons'
export const COUPONS_REPORT = '/relatorio-cupons'
export const LEADS_REPORT = '/relatorio-leads'
export const CLASS_FEEDBACKS = '/avaliacoes-aulas'
export const COMMENTED_TESTS_FEEDBACKS = '/avaliacoes-provas-comentadas'
export const SUPPORT_FEEDBACKS = '/avaliacoes-monitoria'
export const SUPPORT_REPORT = '/relatorio-monitoria'
export const PARTNERS_COUPONS_REPORT = '/relatorio-cupons-parceiros'
export const CHECKOUT_PLANS = '/planos-checkout'
export const CHECKOUT_PLANS_CONFIG = '/configuracao-planos-checkout'
export const CREATE_TIMELINE = '/video-timeline/:id'
export const ESSAY_PLAN_CREDITS = '/creditos-redacao'
export const LIVES = '/lives'
export const STUDENTS_REPORT = '/relatorio-alunos'
export const COMMUNITY_MONITOR_REPORT = '/relatorio-monitores-comunidade'
export const COMMUNITY_MONITOR_REVIEW_REPORT = '/relatorio-monitoria-comunidade'
export const PAYMENT_MONITOR_REPORT = '/relatorio-pagamento-monitores'
export const PAYMENT_MONITOR_SETTINGS = '/configuracao-pagamento-monitores'
export const PAYMENT_SETTINGS = '/configuração-pagamento'
export const DISCURSIVE_COURSE = '/curso-discursivas'
export const BLACK_FRIDAY_CONFIG = '/black-friday'
export const ALL_ESSAYS = '/todas-redacoes'
export const ESSAY_COMPETENCE_COMMENTS = '/comentarios-competencias'
export const ESSAY_DETAILS = '/redacao'
export const BOOKANALYSIS = '/analise-obras'
export const VOUCHERS = '/vouchers'
export const VOUCHERS_TEMPLATES = '/vouchers-templates'
export const MASS_VOUCHERS = '/vouchers-massa'

export const toEditRoute = curry((route, id = ':id') => `${route}/${id}/editar`)
export const toDetailRoute = curry(
  (route, id = ':id', userId = ':userId') => `${route}/${id}&${userId}/detalhes`
)
export const toSearchRoute = curry(
  (route, id = ':id', type = ':type') => `${route}/${id}&${type}`
)
