import styled from 'styled-components'
import { Form as AntdForm } from 'antd'
import { spacing } from 'resources'

/**
 * @type {import('antd/lib/form/FormItem')} FormItem
 */
const FormItem = styled(AntdForm.Item)`
  margin-bottom: ${spacing(2)};
`

/**
 * @typedef {Object} Form
 * @property {import('antd/lib/form/FormItem').default} Item
 *
 * @typedef {import('antd/lib/form').FormInstance} AntdForm
 */

/**
 * @type {AntdForm & Form}
 */
const Form = styled(AntdForm)`
  margin-bottom: 0;
`

Form.Item = FormItem

export { Form }
