import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useCouponsSelect } from './use-coupons-fetcher'

const CouponsSelect = ({ loading = false, onFetchCompleted, ...antdProps }) => {
  const { coupons = [], loading: isFetching } = useCouponsSelect(onFetchCompleted)

  return (
    <Select
      {...antdProps}
      render
      defaultActiveFirstOption
      loading={isFetching || loading}
      emptyText='Nenhum cupom encontrado'
    >
      {coupons.map((coupon) => (
        <Option value={coupon.id} key={coupon.id}>
          {coupon.title}
        </Option>
      ))}
    </Select>
  )
}

CouponsSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  onFetchCompleted: t.func,
}

export { CouponsSelect }
