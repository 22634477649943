import { useQuery, gql } from '@apollo/client'
import { getNodes } from 'resources'

const useClassGroupFetcher = ({ disciplineId }) => {
  const { data } = useQuery(CLASS_GROUP_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: { disciplineId: disciplineId || null, showAll: true },
      pagination: {},
    },
  })

  return {
    classGroups: getNodes('classGroups', data),
  }
}

const CLASS_GROUP_QUERY = gql`
  query GetClassGroups($pagination: PaginationInput!, $filter: FilterClassGroupsInput){
    classGroups(pagination: $pagination, filter: $filter){
      nodes {
        id
        title
      }
    }
  }
`

export { useClassGroupFetcher }
