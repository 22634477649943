import React from 'react'

const UploadIcon = () => (
  <svg width='50' height='40' viewBox='0 0 50 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='50' height='40' rx='2' fill='#0041F8' />
    <path d='M10.3921 20.9094L4 28.5V5H46V29.5L34.5324 15.8271C33.7331 14.874 32.2669 14.874 31.4676 15.8271L20 29.5L13.5128 20.9855C12.7368 19.967 11.2169 19.9299 10.3921 20.9094Z' fill='white' />
    <circle cx='13' cy='11' r='4' fill='#0041F8' />
    <circle cx='13' cy='11' r='1' fill='#FFF2F2' />
  </svg>
)

export { UploadIcon }
