import React from 'react'
import t from 'prop-types'
import { makeArray } from 'resources/composable'
import { EmptyState } from 'ui/empty-state'

import { EmptyStateContainer, Wrapper, FormItem, Item, TextArea } from './components'

const StudyListSchedule = ({ amountOfWeeks }) => {
  const weeks = makeArray(amountOfWeeks)

  if (amountOfWeeks === 0) {
    return (
      <EmptyStateContainer>
        <EmptyState
          imageSize={90}
          description='Selecione as disciplinas e informe o número de semanas.'
        />
      </EmptyStateContainer>
    )
  }

  return (
    <Wrapper>
      {weeks.map((_, id) => (
        <Item key={id}>
          <FormItem name={`${id}-schedule`} label={`Semana ${id + 1}`}>
            <TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
          </FormItem>
        </Item>
      ))}
    </Wrapper>
  )
}

StudyListSchedule.propTypes = {
  amountOfWeeks: t.number.isRequired,
}

export { StudyListSchedule }
