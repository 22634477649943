import styled, { css } from 'styled-components'
import { Typography, Divider as AntdDivider } from 'antd'
import { getPrimaryVariant, getColorVariant } from 'resources'
import { DraggableCard } from 'ui'

export const Container = styled.div`
  padding: 1.5rem 2rem;
  background: #E5ECFE;
`

export const Divider = styled(AntdDivider)`
  margin: 0 1.5rem;
`

export const List = styled.ul`
  max-height: 30rem;
  overflow: auto;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListTitle = styled(Typography.Title)`
  && {
    color: ${getPrimaryVariant('main')};
  }
`

export const DeleteButton = styled.button`
  border: 0;
  background: 0;
  padding: 0;
  margin: 0;
  outline: 0;
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  align-self: center;
  cursor: pointer;

  .anticon {
    color: ${getColorVariant('danger', 'main')};
    font-size: 1rem;
  }
`

export const TimelineButton = styled.button`
  border: 0;
  outline: 0;
  background: 0;
  padding: 0;
  margin: 0;
  display:flex;
  position: absolute;
  right: 2.6rem;
  top: 0.6rem;
  cursor:pointer;

  .anticon {
    color: ${getColorVariant('primary', 'main')};
    font-size: 1.0rem;
  }

`

export const Card = styled(DraggableCard)`
  && {
    border: none;
    background: transparent;

    .ant-card-body {
      background: #fff;
      border-radius: 2px;

      ${props => props.isDragging && css`
        transform: rotate(1.25deg);
        border: solid 2px ${getColorVariant('primary', 'main')};
      `}
    }
  }

  ${DeleteButton}, ${TimelineButton} {
    opacity: 0;
    transition: 200ms;
  }

  &:hover ${DeleteButton}, &:hover ${TimelineButton} {
    opacity: 1;
  }

`

export const InfosContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`

export const Item = styled.li`
  position: relative;
  list-style: none;
  margin-bottom: .75rem;
`

export const Title = styled(Typography.Title)`
  &&& {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
  }
`

export const Position = styled(Typography.Text)`
  font-size: 1.25rem;
  font-weight: bold;
  margin-right: 2rem;
  color: ${getColorVariant('primary', 'main')};
`

export const Type = styled(Typography.Text)`
  &&& {
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: bold;
    margin:0;
    color: ${getColorVariant('gray', 'main')};
  }
`

export const AddResourceButton = styled.button`
  padding: 1rem 0;
  background: 0;
  border: 0;
  color: ${getPrimaryVariant('main')};
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  outline: 0;

  .anticon {
    color: ${getPrimaryVariant('main')};
    margin-right: 1rem;
  }
`
