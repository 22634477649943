import { useCallback, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { evolve, map, concat, slice, propEq, pipe, identity, ifElse, filter, append, not } from 'ramda'
import { conditionalMap, indexEq, filterI } from 'resources/composable'
import { useSetState } from 'resources'

const useStudyListResources = () => {
  const [waitingFor, toggleWaiting] = useSetState(initialWaitingFor)
  const [drawerConfig, setDrawerConfig] = useState(initialDrawerConfig)
  const [resourcesData, setResourcesData] = useState(initialresourcesData)
  const [flag, setFlag] = useState(false)
  const [warning, setWarning] = useState(false)

  const [activeTab, setActiveTab] = useState(null)

  const [columns, setColumns] = useState({})
  const weeksDebounce = useDebouncedCallback(
    value => {
      setResourcesData(value)
      toggleWaiting(initialWaitingFor)
    },
    1000
  )

  const setAmountOfWeeks = useCallback(amountOfWeeks => {
    toggleWaiting({ amountOfWeeks: true })
    weeksDebounce.callback({
      disciplines: updateWeeks(amountOfWeeks, resourcesData),
      amountOfWeeks: +amountOfWeeks,
      disciplinesAdmin: resourcesData.disciplinesAdmin && updateWeeks2(amountOfWeeks, resourcesData),
    })
  }, [resourcesData, toggleWaiting, weeksDebounce])

  const setDisciplines = useCallback((_, disciplines) => {
    toggleWaiting({ disciplines: true })
    weeksDebounce.callback({
      ...resourcesData,
      disciplines: addDisciplines(disciplines, resourcesData),
      disciplinesAdmin: addResourceToNewDiscipline(disciplines[disciplines.length - 1].key, resourcesData.amountOfWeeks),
    })
    setWarning(true)
    setActiveTab(null)
  }, [resourcesData, toggleWaiting, weeksDebounce])

  const addResource = useCallback(resource => {
    const { weekId, disciplineId } = drawerConfig

    if (resourcesData?.disciplinesAdmin) {
      const addResource = addResourceToDiscipline1(resource, disciplineId, weekId, resourcesData)

      setResourcesData(addResource)
      setFlag(true)
      setDrawerConfig(initialDrawerConfig)
      setWarning(true)
      return
    }

    const { disciplines } = resourcesData
    const addResource = addResourceToDiscipline(resource, disciplineId, weekId)
    const updatedDisciplines = disciplines.map(addResource)

    setResourcesData({ ...resourcesData, disciplines: updatedDisciplines })
    setDrawerConfig(initialDrawerConfig)
  }, [drawerConfig, resourcesData])

  const removeResource = useCallback((disciplineId, weekId, resourceId) => {
    if (resourcesData.disciplinesAdmin) {
      const removed = removeResourceFromDiscipline(disciplineId, weekId, resourceId, resourcesData)

      weeksDebounce.callback({
        ...resourcesData,
        disciplinesAdmin: removed,
      })
      return
    }
    const { disciplines } = resourcesData

    setResourcesData({
      ...resourcesData,
      disciplines: removeDisciplineResource(
        disciplineId,
        weekId,
        resourceId,
        disciplines
      ),
    })
  }, [resourcesData, weeksDebounce])

  const reorderResource = useCallback((result, disciplineId, weekId) => {
    if (!result.destination) return

    const { disciplines } = resourcesData

    const reorderResources = result => resources => {
      const { source, destination } = result
      const newResources = Array.from(resources)
      const [resource] = newResources.splice(source.index, 1)
      newResources.splice(destination.index, 0, resource)
      return newResources
    }

    const updated = disciplines.map(ifElse(
      propEq('id', disciplineId),
      evolve({
        weeks: conditionalMap(
          indexEq(weekId),
          evolve({
            resources: reorderResources(result),
          }),
          identity
        ),
      }),
      identity
    ))

    setResourcesData({
      ...resourcesData,
      disciplines: updated,
    })
  }, [resourcesData])

  const clearResourcesData = () => setResourcesData(initialresourcesData)

  const closeDrawer = useCallback(() => setDrawerConfig(initialDrawerConfig), [])

  const openDrawer = useCallback((weekId, disciplineId) =>
    setDrawerConfig({
      visible: true,
      disciplineId,
      weekId,
    }), [])

  return {
    resourcesData,
    drawerConfig,
    addResource,
    setAmountOfWeeks,
    setDisciplines,
    openDrawer,
    closeDrawer,
    removeResource,
    reorderResource,
    waitingFor,
    clearResourcesData,
    setResourcesData,
    toggleWaiting,
    setColumns,
    columns,
    flag,
    setFlag,
    setWarning,
    warning,
    setActiveTab,
    activeTab,
  }
}

const emptyWeek = { resources: [] }

const initialWaitingFor = {
  amountOfWeeks: false,
  disciplines: false,
  disciplinesAdmin: false,
}

const initialresourcesData = {
  amountOfWeeks: 0,
  disciplines: [],
}

const initialDrawerConfig = {
  visible: false,
  weekId: null,
  disciplineId: null,
}

const createWeeks = amount => Array(amount).fill(emptyWeek)

const createDiscipline = amount => ({ children, value }) => ({
  id: value,
  name: children,
  weeks: createWeeks(amount),
})

const addDisciplines = (newDisciplines, { disciplines, amountOfWeeks }) => {
  if (newDisciplines.length === 0) return []

  return pipe(
    filter(discipline => !disciplines.some(propEq('id', discipline.key))),
    map(createDiscipline(amountOfWeeks)),
    concat(disciplines),
    filter(discipline => newDisciplines.some(propEq('key', discipline.id)))
  )(newDisciplines)
}

const removeWeeks = amount => evolve({
  weeks: slice(0, amount),
})

const addWeeks = amount => evolve({
  weeks: weeks => concat(weeks, createWeeks(amount - weeks.length)),
})

const addResourceToDiscipline = (resource, disciplineId, weekId) => ifElse(
  propEq('id', disciplineId),
  evolve({
    weeks: conditionalMap(
      indexEq(weekId),
      evolve({
        resources: append(resource),
      }),
      identity
    ),
  }),
  identity
)

const addResourceToDiscipline1 = (resource, disciplineId, weekId, resourcesData) => {
  const disciplineKey = `${disciplineId}-${weekId}`
  if (disciplineKey in resourcesData.disciplinesAdmin) {
    resourcesData.disciplinesAdmin[disciplineKey].items.resources.push({ order: getLastIndex(disciplineKey, resourcesData.disciplinesAdmin), type: resource.type, item: resource })

    return resourcesData
  }
}
const getLastIndex = (disciplineKey, disciplinesAdmin) => {
  if (disciplineKey in disciplinesAdmin) {
    const discipline = disciplinesAdmin[disciplineKey]
    const resources = discipline.items.resources

    return resources.length + 1
  }
}

const updateWeeks = (newAmount, { amountOfWeeks, disciplines }) => {
  if (newAmount === amountOfWeeks) {
    return disciplines
  }

  const fn = newAmount > amountOfWeeks ? addWeeks : removeWeeks
  return disciplines.map(fn(newAmount))
}

const updateWeeks2 = (newAmount, { amountOfWeeks, disciplinesAdmin }) => {
  const updateddisciplinesAdmin = { ...disciplinesAdmin }
  const keys = Object.keys(updateddisciplinesAdmin)

  const diff = newAmount - amountOfWeeks

  if (diff < 0) {
    const keysToRemove = keys.slice(diff)
    keysToRemove.forEach(key => delete updateddisciplinesAdmin[key])
  } else if (diff > 0) {
    const lastKey = keys[keys.length - 1]
    for (let i = 1; i <= diff; i++) {
      const newKey = lastKey.slice(0, -1) + (parseInt(lastKey.slice(-1)) + i)
      updateddisciplinesAdmin[newKey] = { ...updateddisciplinesAdmin[lastKey] }
      updateddisciplinesAdmin[newKey].title = `Semana ${keys.length + i}`
      updateddisciplinesAdmin[newKey].items = { resources: [] }
      updateddisciplinesAdmin[newKey].items.weekNumber = parseInt(lastKey.slice(-1)) + i
    }
  }

  return updateddisciplinesAdmin
}

const addResourceToNewDiscipline = (disciplineId, numberOfWeeks) => {
  const disciplinesAdmin = {}

  for (let i = 0; i < numberOfWeeks; i++) {
    const key = `${disciplineId}-${i}`
    disciplinesAdmin[key] = {
      title: `Semana ${i + 1}`,
      items: {
        weekNumber: i,
        resources: [],
      },
      disciplineId: disciplineId,
    }
  }

  return disciplinesAdmin
}
const removeResourceFromDiscipline = (disciplineId, weekId, resourceId, { disciplinesAdmin }) => {
  const disciplineKey = `${disciplineId}-${weekId}`

  if (disciplineKey in disciplinesAdmin) {
    const discipline = disciplinesAdmin[disciplineKey]
    const resources = discipline.items.resources

    if (resourceId >= 0 && resourceId < resources.length) {
      resources.splice(resourceId, 1)
    }
  }

  return disciplinesAdmin
}

const indexNotEq = index => pipe(
  indexEq(index),
  not
)

const findAndRemove = (weekId, resourceId) =>
  conditionalMap(
    indexEq(weekId),
    evolve({
      resources: filterI(indexNotEq(resourceId)),
    }),
    identity
  )

const removeDisciplineResource = (disciplineId, weekId, resourceId, disciplines) =>
  conditionalMap(
    propEq('id', disciplineId),
    evolve({ weeks: findAndRemove(weekId, resourceId) }),
    identity,
    disciplines
  )

export { useStudyListResources }
