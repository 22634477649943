
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default reorder

export const reorderStudyPlanMap = ({ studyPlanMap, source, destination }) => {
  const current = [...studyPlanMap[source.droppableId].items.resources]
  const next = [...studyPlanMap[destination.droppableId].items.resources]
  const target = current[source.index]

  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index)
    const result = {
      ...studyPlanMap,
      [source.droppableId]: {
        ...studyPlanMap[source.droppableId],
        items: {
          ...studyPlanMap[source.droppableId].items,
          resources: reordered,
        },
      },
    }
    return {
      studyPlanMap: result,
    }
  }
  current.splice(source.index, 1)
  next.splice(destination.index, 0, target)

  const result = {
    ...studyPlanMap,
    [source.droppableId]: {
      ...studyPlanMap[source.droppableId],
      items: {
        ...studyPlanMap[source.droppableId].items,
        resources: current,
      },
    },
    [destination.droppableId]: {
      ...studyPlanMap[destination.droppableId],
      items: {
        ...studyPlanMap[destination.droppableId].items,
        resources: next,
      },
    },
  }

  return {
    studyPlanMap: result,
  }
}

export function moveBetween ({ list1, list2, source, destination }) {
  const newFirst = Array.from(list1.values)
  const newSecond = Array.from(list2.values)

  const moveFrom = source.droppableId === list1.id ? newFirst : newSecond
  const moveTo = moveFrom === newFirst ? newSecond : newFirst

  const [moved] = moveFrom.splice(source.index, 1)
  moveTo.splice(destination.index, 0, moved)

  return {
    list1: {
      ...list1,
      values: newFirst,
    },
    list2: {
      ...list2,
      values: newSecond,
    },
  }
}
