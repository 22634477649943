import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useVoucherSelect } from './use-voucher-select'

const VoucherSelect = ({ loading = false, onFetchCompleted, ...antdProps }) => {
  const { vouchersTemplates, loading: isFetching } = useVoucherSelect(onFetchCompleted)
  return (
    <Select
      {...antdProps}
      render
      defaultActiveFirstOption
      loading={isFetching || loading}
      emptyText='Nenhum voucher encontrado'
      allowClear
    >
      {vouchersTemplates.map(voucher => (
        <Option
          value={voucher.id}
          key={voucher.id}
        >
          {voucher.title}
        </Option>
      ))}
    </Select>
  )
}

VoucherSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  onFetchCompleted: t.func,
}

export { VoucherSelect }
