import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'

import { DeleteAction, EditAction } from './common-actions'
import { ActionItem } from './action-item'

export const TableActions = ({ children, className }) => (
  <Container className={className}>
    {children}
  </Container>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  > * {
    margin-left: .75rem;
  }
`

TableActions.propTypes = {
  children: t.node.isRequired,
  className: t.string,
}

TableActions.DeleteAction = DeleteAction
TableActions.EditAction = EditAction
TableActions.Item = ActionItem
