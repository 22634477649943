import React from 'react'
import t from 'prop-types'
import { PlusOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { ResourceTypes } from 'resources'
import { EmptyMessage } from 'ui'

import { List, AddResourceButton, Container, ListTitle } from './components'
import { ParentSubjectItem, ResourceItem, SubjectItem, PdfsItem } from './resource-item'

const ClassGroupList = ({ resources = [], onAddClick, onDelete, onDragEnd }) => (
  <Container>
    {resources.length > 0 && (
      <ListTitle level={4}>
        Itens
      </ListTitle>
    )}

    <List>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='drop'>
          {({ innerRef, droppableProps, placeholder }) => (
            <div ref={innerRef} {...droppableProps}>

              {resources.map(({ type, ...resource }, index) => {
                if (type === ResourceTypes.QUESTIONS_SUBJECT && resource.parentSubject) {
                  return (
                    <ParentSubjectItem
                      onDelete={onDelete}
                      key={resource.slug}
                      slug={resource.slug}
                      name={resource.name}
                      parentName={resource.parentSubject.name}
                      position={index}
                    />
                  )
                }

                if (type === ResourceTypes.QUESTIONS_SUBJECT) {
                  return (
                    <SubjectItem
                      onDelete={onDelete}
                      key={resource.slug}
                      slug={resource.slug}
                      name={resource.name}
                      position={index}
                    />
                  )
                }

                if (type === ResourceTypes.PDFS) {
                  return (
                    <PdfsItem
                      onDelete={onDelete}
                      key={resource.slug}
                      slug={resource.slug}
                      title={resource.title}
                      discipline={resource.discipline?.name || resource.discipline}
                      position={index}
                    />
                  )
                }
                return (
                  resource.id
                    ? (
                      <ResourceItem
                        videoId={resource?.mainVideo?.id}
                        onDelete={onDelete}
                        key={resource.slug}
                        slug={resource.slug}
                        type={type}
                        name={resource.title}
                        position={index}
                      />
                    ) : resource.sub ? (
                      <ResourceItem
                        videoId={resource?.mainVideo?.id}
                        onDelete={onDelete}
                        key={resource.slug}
                        slug={resource.slug}
                        type={type}
                        name={`Simulado ${resource.sub[0].name}`}
                        position={index}
                      />
                    ) : (
                      <ResourceItem
                        videoId={resource?.mainVideo?.id}
                        onDelete={onDelete}
                        key={resource.slug}
                        slug={resource.slug}
                        type={type}
                        name={resource.title}
                        position={index}
                      />
                    )
                )
              })}

              {placeholder}

            </div>
          )}
        </Droppable>
      </DragDropContext>
      {resources.length === 0 && (
        <EmptyMessage
          onAddClick={onAddClick}
        />
      )}
    </List>
    {resources.length > 0 && (
      <AddResourceButton onClick={onAddClick} type='button'>
        <PlusOutlined />
        Adicionar item
      </AddResourceButton>
    )}
  </Container>
)

ClassGroupList.propTypes = {
  resources: t.array.isRequired,
  onAddClick: t.func.isRequired,
  onDragEnd: t.func.isRequired,
  onDelete: t.func.isRequired,
}

export { ClassGroupList }
