import React from 'react'
import { Select, Option } from 'ui'
import { useAudiencesFetcher } from './use-audiences-fecher'

const AudienceSelect = antdProps => {
  const { audiences, loading } = useAudiencesFetcher()

  return (
    <Select
      {...antdProps}
      loading={loading}
      emptyText='Nenhum público encontrado'
    >
      {audiences.map(({ id, title }) => (
        <Option value={id} key={id}>{title}</Option>
      ))}
    </Select>
  )
}

export { AudienceSelect }
