import { gql, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { CONTEXTS, getNodes } from 'resources'
import { useDebouncedCallback } from 'use-debounce'

export const QUESTIONS_QUERY = gql`
  query FetchQuestions(
    $filter: FilterQuestionsInput
    $pagination: PaginationInput!
  ) {
    questions(filter: $filter, pagination: $pagination) {
      nodes {
        id
        code
      }
    }
  }
`

const useQuestionsFetcher = (contextId = CONTEXTS.IDs.DEFAULT) => {
  const [isSearching, setSearching] = useState(false)

  const { data, loading, refetch } = useQuery(QUESTIONS_QUERY, {
    variables: {
      pagination,
      filter: {
        contextId,
      },
    },
  })

  const handleSearch = async (value) => {
    setSearching(true)

    await refetch({
      pagination,
      filter: {
        contextId,
        code: value || null,
      },
    })

    setSearching(false)
  }

  const debounced = useDebouncedCallback(handleSearch, 1000)

  useEffect(() => {
    refetch({
      pagination,
      filter: {
        contextId,
      },
    })
  }, [contextId, refetch])

  return {
    questions: isSearching ? [] : getNodes('questions', data),
    isFetching: loading,
    isSearching,
    handleSearch: debounced.callback,
  }
}

const pagination = {
  perPage: 10,
}

export { useQuestionsFetcher }
