import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useStatesFetcher } from './use-state-fetcher'

const StateSelect = ({
  loading = false,
  valueName = 'id',
  onFetchCompleted,
  getValue,
  onSelect,
  ...antdProps
}) => {
  const { states, loading: isFetching } = useStatesFetcher(
    onFetchCompleted
  )

  return (
    <Select
      {...antdProps}
      render='true'
      defaultActiveFirstOption
      loading={isFetching || loading}
      emptyText='Nenhum estado encontrado'
      onSelect={onSelect}
      allowClear
    >
      {states.map((state) => (
        <Option value={getValue ? getValue(state) : state[valueName]} key={state[valueName]}>
          {state.name}
        </Option>
      ))}
    </Select>
  )
}

StateSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  getValue: t.func,
  onFetchCompleted: t.func,
  onSelect: t.func,
}

export { StateSelect }
