import React from 'react'
import t from 'prop-types'
import { BurgerButton, Avatar } from 'ui'

import { useMe } from './use-me'
import { Container, BurgerButtonContainer, Name } from './components'

const Header = ({ onBurgerButtonClick }) => {
  const { user } = useMe()
  return (
    <Container>
      <BurgerButtonContainer>
        <BurgerButton onClick={onBurgerButtonClick} />
      </BurgerButtonContainer>
      <div>
        <Avatar
          name={user.name}
          photo={user.photo}
          size={42}
        />
        <Name>{user.name}</Name>
      </div>
    </Container>
  )
}
Header.propTypes = {
  onBurgerButtonClick: t.func.isRequired,
}

export { Header }
