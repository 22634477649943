import 'typeface-montserrat'
import React from 'react'
import { hot } from 'react-hot-loader'
import { createGlobalStyle } from 'styled-components'
import { ApolloProvider } from '@apollo/client'
import client from './apollo-client'

import Providers from './providers'
import App from './app'

function Root() {
  return (
    <ApolloProvider client={client}>
      <Providers>
        <GlobalStyle />
        <App />
        <div id='portal-root' />
      </Providers>
    </ApolloProvider>
  )
}

const GlobalStyle = createGlobalStyle`
  html, body, #root{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 100%;
  }
`

export default hot(module)(Root)
