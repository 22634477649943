import { gql, useQuery } from '@apollo/client'
import { getNodes } from 'resources'

export const SIMULATEDS_QUERY = gql`
  query GetSimulated(
    $pagination: PaginationInput
    $filter: FilterSimulatedsInput
  ) {
    simulateds(pagination: $pagination, filter: $filter) {
      nodes {
        id
        slug
        title
      }
    }
  }
`

const useSimulatedsFetcher = (isPublished, contextId) => {
  const { data, loading } = useQuery(SIMULATEDS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      pagination: {
        perPage: 80,
        page: 1,
      },
      filter: {
        isPublished,
        contextId,
      },
    },
  })

  return {
    simulateds: getNodes('simulateds', data),
    isFetching: loading,
  }
}

export { useSimulatedsFetcher }
