import React from 'react'
import t from 'prop-types'
import { PlusOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { preventDefault } from 'resources/composable'
import { pipe } from 'ramda'
import { ResourceTypes } from 'resources'

import { ResourceItem, ParentSubjectItem, PdfsItem, VideoGroupItem } from './resource-item'
import { List, WeekWrapper, Title, WeekHeader, AddItem, ListContainer } from './components'
import { EmptyWeek } from './empty-week'

const Week = ({ onAdd, onRemove, items, id, onDragEnd }) => {
  return (
    <WeekWrapper>
      <WeekHeader>
        <Title> Semana {id + 1} </Title>
      </WeekHeader>

      <ListContainer>
        <DragDropContext onDragEnd={result => onDragEnd(result)}>
          <Droppable droppableId='drop'>
            {({ innerRef, droppableProps, placeholder }) => (
              <List
                isEmpty={!items.length}
                ref={innerRef}
                {...droppableProps}
              >
                {items.length === 0 && <EmptyWeek />}

                {items.map((row, id) => {
                  return (
                    row.parentSubject
                      ? (
                        <ParentSubjectItem
                          onRemove={_ => onRemove(id)}
                          key={id}
                          id={id}
                          title={row.name}
                          parentSubject={row.parentSubject}
                        />
                      )

                      : row.sub ? (
                        <ResourceItem
                          onRemove={_ => onRemove(id)}
                          key={id}
                          id={id}
                          type={row.type}
                          title={`Simulado ${row.sub[0].name}`}
                        />
                      )

                        : row.type === ResourceTypes.PDFS ? (
                          <PdfsItem
                            onRemove={_ => onRemove(id)}
                            key={id}
                            id={id}
                            title={row.title}
                            discipline={row.discipline?.name || row.discipline}
                          />
                        )
                          : row.type === ResourceTypes.VIDEO_GROUP ? (
                            <VideoGroupItem
                              onRemove={_ => onRemove(id)}
                              key={id}
                              id={id}
                              videos={row.videos}
                              title={row?.videoGroupTitle || row.videos[0].videoGroupTitle}
                            />
                          )
                            : (
                              <ResourceItem
                                onRemove={_ => onRemove(id)}
                                key={id}
                                id={id}
                                type={row.type}
                                title={row.title || row.name}
                              />
                            )
                  )
                })}
                {placeholder}
              </List>
            )}
          </Droppable>

        </DragDropContext>

      </ListContainer>

      <AddItem onClick={pipe(preventDefault, onAdd)}>
        <PlusOutlined />
        Adicionar item
      </AddItem>
    </WeekWrapper>
  )
}

Week.propTypes = {
  items: t.array.isRequired,
  id: t.number.isRequired,
  onAdd: t.func.isRequired,
  onRemove: t.func.isRequired,
  onDragEnd: t.func.isRequired,
}

export { Week }
