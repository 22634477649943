/* eslint-disable no-unused-expressions */
import React, { useCallback } from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { useKnowledgeAreasFetcher } from './use-knowledge-area-fetcher'

/**
 * @type Select
 */
const KnowledgeAreaSelect = ({
  loading = false,
  valueName = 'id',
  onFetchCompleted,
  getValue,
  onSelect,
  onKnowledgeAreaChange,
  ...antdProps
}) => {
  const { knowledgeAreas, loading: isFetching } =
    useKnowledgeAreasFetcher(onFetchCompleted)

  const toggleKnowledgeAreaChange = useCallback(
    (value) => {
      onKnowledgeAreaChange?.(
        knowledgeAreas.find(
          (knowledgeArea) => knowledgeArea[valueName] === value
        )
      )
      onSelect?.(value)
    },
    [onKnowledgeAreaChange, onSelect, knowledgeAreas, valueName]
  )

  return (
    <Select
      {...antdProps}
      render='true'
      defaultActiveFirstOption
      loading={isFetching || loading}
      emptyText='Nenhum tipo de questão encontrada'
      onSelect={toggleKnowledgeAreaChange}
      allowClear
    >
      {knowledgeAreas.map((knowledgeArea) => (
        <Option
          value={getValue ? getValue(knowledgeArea) : knowledgeArea[valueName]}
          key={knowledgeArea[valueName]}
        >
          {knowledgeArea.name}
        </Option>
      ))}
    </Select>
  )
}

KnowledgeAreaSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  getValue: t.func,
  onFetchCompleted: t.func,
  onSelect: t.func,
  onKnowledgeAreaChange: t.func,
}

export { KnowledgeAreaSelect }
