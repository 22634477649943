import React from 'react'
import { Form } from 'ui'
import styled from 'styled-components'
import { Button, Row, Col } from 'antd'
import t from 'prop-types'

const TableFilter = ({ form, onSubmit, children }) => (
  <Form onFinish={onSubmit} form={form}>
    <Row gutter={[12, 0]}>
      {children}
      <ButtonContainer xs={10}>
        <Button block htmlType='submit' size='large' type='primary'>
          Pesquisar
        </Button>
      </ButtonContainer>
    </Row>
  </Form>
)

const ButtonContainer = styled(Col)`
`

TableFilter.propTypes = {
  onSubmit: t.func.isRequired,
  children: t.object.isRequired,
  form: t.object,
}

export { TableFilter }
