import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

import { useQuestionsFetcher } from './use-questions-fetcher'
import { Spinner } from 'ui/spinner'
import { CONTEXTS } from 'resources'

const QuestionsSelect = ({
  loading,
  contextId = CONTEXTS.IDs.DEFAULT,
  disciplines = [],
  ...antdProps
}) => {
  const { questions, isFetching, isSearching, handleSearch } =
    useQuestionsFetcher(contextId, disciplines)

  return (
    <Select
      {...antdProps}
      showSearch
      loading={loading || isFetching || isSearching}
      onSearch={handleSearch}
      filterOption={false}
      notFoundContent={isSearching ? <Spinner /> : null}
      emptyText='Nenhuma questão encontrada'
    >
      {questions.map(({ id, code }) => (
        <Option value={id} key={id}>
          {code}
        </Option>
      ))}
    </Select>
  )
}

QuestionsSelect.propTypes = {
  loading: t.bool,
  contextId: t.number,
  disciplines: t.array,
}

export { QuestionsSelect }
