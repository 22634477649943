import React, { useState } from 'react'
import t from 'prop-types'
import { Tabs, WeeksContainer } from './components'
import Column from './Column'
import reorder, { reorderStudyPlanMap } from './reorder'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { openConfirmDialogStudyPlan } from 'resources'

const StudyListWeeks =
  ({
    onAddClick,
    onRemoveClick,
    disciplines = [],
    disciplinesAdmin = {},
    isCombineEnabled,
    useClone,
    withScrollableColumns,
    setFilter,
    flag,
    setFlag,
    setResourcesData,
    toggleWaiting,
    setWarning,
    warning,
    onSubmit,
    setDisableNumberOfWeeks,
    setActiveTab,
    activeTab,
  }) => {
    const [, setOrdered] = useState({})

    const handleTabClick = (key) => {
      if (!warning) {
        setActiveTab(key)
        setFilter((prev) => ({ ...prev, disciplineId: key }))
        setDisableNumberOfWeeks(false)
        toggleWaiting({ disciplinesAdmin: true })
        return
      }
      openConfirmDialogStudyPlan({
        title: 'Aviso!',
        content: 'Houve alteração no conteúdo das semanas, necessário salvar a alteração antes de trocar de disciplina.',
        onConfirm: () => onSubmit(),
      })
    }

    const onDragEnd = (result) => {
      setWarning(true)
      if (result.combine) {
        if (result.type === 'COLUMN') {
          const shallow = [...Object.keys(disciplinesAdmin)]
          shallow.splice(result.source.index, 1)
          setOrdered(shallow)
          return
        }

        const column = disciplinesAdmin[result.source.droppableId]
        const withStudyPlanRemoved = [...column]

        withStudyPlanRemoved.splice(result.source.index, 1)

        const orderedColumns = {
          ...disciplinesAdmin,
          [result.source.droppableId]: withStudyPlanRemoved,
        }
        setResourcesData(orderedColumns)
        return
      }

      if (!result.destination) {
        return
      }

      const source = result.source
      const destination = result.destination

      if (
        source.droppableId === destination.droppableId &&
      source.index === destination.index
      ) {
        setWarning(false)
        return
      }

      if (result.type === 'COLUMN') {
        const reorderedorder = reorder(Object.keys(disciplinesAdmin), source.index, destination.index)

        setOrdered(reorderedorder)

        return
      }

      const data = reorderStudyPlanMap({
        studyPlanMap: disciplinesAdmin,
        source,
        destination,
      })

      setResourcesData((prev) => ({ ...prev, disciplinesAdmin: data.studyPlanMap }))
    }

    return (
      <>
        <Tabs animated={false} activeKey={activeTab} onTabClick={handleTabClick}>
          {disciplines.map(({ name, id: disciplineId, weeks }) => (
            <Tabs.Pane tab={name} key={disciplineId}>
              <DragDropContext key={disciplineId} onDragEnd={onDragEnd}>
                <Droppable
                  droppableId='board'
                  type='COLUMN'
                  direction='horizontal'
                  isCombineEnabled={isCombineEnabled}
                >
                  {(provided) => (
                    <WeeksContainer ref={provided.innerRef} {...provided.droppableProps} scroll={false}>
                      {disciplinesAdmin ? Object.keys(disciplinesAdmin).map((key, index) => {
                        return (
                          <Column
                            key={key}
                            index={index}
                            title={disciplinesAdmin[key]?.title}
                            studyPlans={disciplinesAdmin[key]}
                            isScrollable={withScrollableColumns}
                            isCombineEnabled={isCombineEnabled}
                            useClone={useClone}
                            id={key}
                            onAdd={_ => onAddClick(index, disciplineId)}
                            flag={flag}
                            setFlag={setFlag}
                            onRemove={resourceId => onRemoveClick(disciplineId, index, resourceId)}
                          />
                        )
                      }) : null}
                      {provided.placeholder}
                    </WeeksContainer>
                  )}
                </Droppable>
              </DragDropContext>
            </Tabs.Pane>
          ))}
        </Tabs>
      </>
    )
  }
StudyListWeeks.propTypes = {
  disciplines: t.array,
  onAddClick: t.func.isRequired,
  onRemoveClick: t.func.isRequired,
  onDragEnd: t.func.isRequired,
  disciplinesAdmin: t.object,
  isCombineEnabled: t.bool,
  useClone: t.bool,
  withScrollableColumns: t.bool,
  setFilter: t.func,
  flag: t.bool,
  setFlag: t.func,
  setResourcesData: t.func,
  toggleWaiting: t.func,
  setWarning: t.func,
  warning: t.bool,
  onSubmit: t.func,
  setDisableNumberOfWeeks: t.func,
  setActiveTab: t.func,
  activeTab: t.bool,
}

export { StudyListWeeks }
