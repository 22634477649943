import React from 'react'

export const QuestionsIcon = () => (
  <svg className='anticon' width='20px' height='18px' viewBox='0 0 18 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
      <g transform='translate(-28.000000, -536.000000)' stroke='#adadad' strokeWidth='1.5'>
        <g>
          <g transform='translate(29.000000, 537.000000)'>
            <path d='M7.61904762,2.33333333 L16,2.33333333' />
            <rect x='0' y='0' width='4.57142857' height='4.66666667' />
            <path d='M7.61904762,11.6666667 L16,11.6666667' />
            <path d='M0,9.33333333 L4.57142857,14' />
            <path d='M4.57142857,9.33333333 L0,14' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
