import { gql, useQuery } from '@apollo/client'
import { getNodes, toBool } from 'resources'
import { always, evolve, pipe, isNil, ifElse } from 'ramda'

const STUDY_LIST_QUERY = gql`
  query StudyListQuery($filter: FilterStudyPlanListsInput) {
    studyPlanLists(filter: $filter) {
      nodes {
        id
        name
      }
    }
  }
`

const useStudyListFetcher = (scheduleType) => {
  const input = normalizeValues({ questionsCourse: scheduleType })
  const { data, loading } = useQuery(STUDY_LIST_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { filter: input },
  })

  return {
    studyLists: getNodes('studyPlanLists', data),
    loading,
  }
}

const normalizeValues = pipe(
  evolve({
    questionsCourse: ifElse(isNil, always(undefined), toBool),
  })
)
export { useStudyListFetcher }
