import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'
import { usePlansFetcher } from './use-plans-fetcher'

const PlansSelect = ({ loading = false, onFetchCompleted, ...antdProps }) => {
  const { plans, loading: isFetching } = usePlansFetcher(onFetchCompleted)

  return (
    <Select
      {...antdProps}
      render
      defaultActiveFirstOption
      loading={isFetching || loading}
      emptyText='Nenhum plano encontrado'
    >
      {plans?.map(plan => (
        <Option
          value={plan.id}
          key={plan.id}
        >
          {plan.name}
        </Option>
      ))}
    </Select>
  )
}

PlansSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  onFetchCompleted: t.func,
}

export { PlansSelect }
