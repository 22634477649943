import React, { useState, useCallback, useEffect } from 'react'
import t from 'prop-types'

import { FileUploader, Text, UploaderContent } from './components'
import { PreviewImage } from './preview-image'
import { UploadIcon } from './upload-icon'

export const IMAGE_FORMATS = ['.jpg', '.jpeg', '.png', '.webp', '.svg']
export const VIDEO_FORMATS = ['.mp4', '.ogg', '.webm', '.mov']

const initialData = { preview: null, file: null }

const ImageUploader = ({
  value,
  isEditing,
  acceptedFormats,
  fieldName,
  imageRemoveCallback,
  onUpload = () => {},
  ...props
}) => {
  const [data, setData] = useState(initialData)

  const acceptedFileFormats = acceptedFormats
    ? acceptedFormats.join(',')
    : IMAGE_FORMATS.join(',')

  const beforeUpload = useCallback((file) => {
    getBase64(file, (preview) => {
      setData({ preview, file })
      onUpload(preview)
    })

    return false
  }, [onUpload])

  useEffect(() => {
    if (isEditing && value) {
      setData({
        preview: value,
        file: true,
      })
    }
  }, [isEditing, value])

  const removeFile = useCallback(() => {
    setData(initialData)

    if (fieldName && imageRemoveCallback) {
      imageRemoveCallback(fieldName)
    }
  }, [setData, fieldName, imageRemoveCallback])

  return !data.file ? (
    <FileUploader
      visible
      accept={acceptedFileFormats}
      showUploadList={false}
      fileList={data.file ? [data.file] : []}
      beforeUpload={beforeUpload}
      value={value}
      {...props}
    >
      <UploaderContent>
        <UploadIcon />
        <Text align='center'>
          {' '}
          Arraste e solte ou clique aqui para buscar um arquivo{' '}
        </Text>
      </UploaderContent>
    </FileUploader>
  ) : (
    <PreviewImage src={data.preview} onRemove={removeFile} />
  )
}
const getBase64 = (file, cb) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => cb(reader.result))
  reader.readAsDataURL(file)
}

ImageUploader.propTypes = {
  value: t.string,
  isEditing: t.bool,
  acceptedFormats: t.array,
  fieldName: t.string,
  imageRemoveCallback: t.func,
  onUpload: t.func,
}

export { ImageUploader }
