import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { media, spacing, usePageTitle, getSidebarProp } from 'resources'

import { PageHeader } from './page-header'

const Page = ({ fullWidth = false, children, title }) => {
  usePageTitle(title)
  return <Container>{children}</Container>
}

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1920px;

  ${media.greaterThan('smallDesktop')`
    padding-left: ${(p) => (p.fullWidth ? 0 : getSidebarProp('width'))}px;
  `}
`

const PageContent = styled.div`
  box-sizing: border-box;
  overflow: auto;
  font-weight: 500;
  padding: 0 ${spacing(7)} ${spacing(2)};

  ${media.lessThan('smallDesktop')`
    padding: 0 ${spacing(4)} ${spacing(2)};
  `}
`

Page.Header = PageHeader
Page.Content = PageContent

Page.propTypes = {
  children: t.node.isRequired,
  title: t.string.isRequired,
  fullWidth: t.bool,
}

export { Page }
