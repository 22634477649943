import React from 'react'
import { getColorVariant } from 'resources'
import styled, { css } from 'styled-components'

const DragIcon = props => (
  <IconContainer {...props}>
    <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='17.0546' cy='2.59893' r='1.64286' transform='rotate(1.20822 17.0546 2.59893)' fill='#d0d0d0' />
      <circle cx='9.77094' cy='2.44531' r='1.64286' transform='rotate(1.20822 9.77094 2.44531)' fill='#d0d0d0' />
      <circle cx='2.48676' cy='2.29168' r='1.64286' transform='rotate(1.20822 2.48676 2.29168)' fill='#d0d0d0' />
      <circle cx='16.9072' cy='9.59737' r='1.64286' transform='rotate(1.20822 16.9072 9.59737)' fill='#d0d0d0' />
      <circle cx='9.62348' cy='9.44375' r='1.64286' transform='rotate(1.20822 9.62348 9.44375)' fill='#d0d0d0' />
      <circle cx='2.3393' cy='9.29012' r='1.64286' transform='rotate(1.20822 2.3393 9.29012)' fill='#d0d0d0' />
      <circle cx='16.7592' cy='16.5958' r='1.64286' transform='rotate(1.20822 16.7592 16.5958)' fill='#d0d0d0' />
      <circle cx='9.47553' cy='16.4422' r='1.64286' transform='rotate(1.20822 9.47553 16.4422)' fill='#d0d0d0' />
      <circle cx='2.19135' cy='16.2886' r='1.64286' transform='rotate(1.20822 2.19135 16.2886)' fill='#d0d0d0' />
    </svg>

  </IconContainer>
)

const IconContainer = styled.div`
  ${props => props.isDragging && css`
    > svg > circle {
      fill: ${getColorVariant('primary', 'main')};
    }
  `}
 `

export { DragIcon }
