import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import t from 'prop-types'
import styled from 'styled-components'
import { isUrlVideo, isFileNameVideo } from '__helpers__/media-helper'
import { Button } from 'antd'

const PreviewImage = ({ src, onRemove }) => {
  const isMediaVideo = src?.file?.name
    ? isFileNameVideo(src.file.name)
    : isUrlVideo(src) || src.includes('data:video')

  const renderMedia = () =>
    isMediaVideo ? (
      <div style={{ textAlign: 'center' }}>
        <Video src={src} autoPlay muted playsInLine loop controls>
          <source src={src} />
          Você está utilizando um navegador muito antigo.
        </Video>
        <Button onClick={onRemove} type='danger' icon={<DeleteOutlined />}>
          Remover
        </Button>
      </div>
    ) : (
      <Image src={src} alt='Pré-visualização do arquivo' />
    )

  return (
    <Wrapper>
      {!isMediaVideo && (
        <Overlay className='overlay'>
          <ButtonIcon onClick={onRemove}>
            <DeleteOutlined />
          </ButtonIcon>
        </Overlay>
      )}
      {renderMedia()}
    </Wrapper>
  )
}

const ButtonIcon = styled.button`
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  box-shadow: 0;
  cursor: pointer;
  outline: none;

  .anticon {
    font-size: 1.75rem;
    color: #fff;
  }
`

const Wrapper = styled.div`
  position: relative;
  min-width: 100px;
  max-width: 200px;
  margin: 1rem auto;

  > .overlay {
    opacity: 0;
    transition: 300ms;

    &:hover {
      opacity: 1;
    }
  }
`

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 200px;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`

const Image = styled.img`
  width: 200px;
  border-radius: 4px;
`

const Video = styled.video`
  width: 200px;
  border-radius: 4px;
`

PreviewImage.propTypes = {
  src: t.string.isRequired,
  onRemove: t.func.isRequired,
}

export { PreviewImage }
