import styled from 'styled-components'
import { getColorVariant } from 'resources'
import { Upload, Typography } from 'antd'

export const Text = styled(Typography.Text)` && {
  margin-top: 1rem;
  font-size: 1rem;
  color: ${getColorVariant('gray', 'main')};
}
`

export const UploaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const FileUploader = styled(Upload.Dragger)`
  && {
    margin: 1rem 0;
    transition: border-color 200ms;
    padding: 2rem 1rem;

    &.ant-upload.ant-upload-drag{
      display: ${props => props.visible ? 'block' : 'none'};
      border: 2px dashed ${getColorVariant('gray', 'light')};
      background: #fff;
    }
  }
`
