import React from 'react'
import t from 'prop-types'
import { Col, Input, Row } from 'antd'
import { Select, Option, Form, DatePicker } from 'ui'
import { required, cep } from 'resources/form-validation'
import { CPFInput } from 'ui/cpf-input/cpf-input'
import { PhoneInput } from 'ui/phone-input/phone-input'

export const StudentForm = ({ hasPasswordField = false, setField }) => {
  const getCep = e => {
    if (e.target.value) {
      const value = e.target.value.replace(/\D/g, '')

      if (value.length === 8) {
        fetch(`https://viacep.com.br/ws/${value}/json/`)
          .then(response => response.json())
          .then(data => {
            data.uf && setField({ state: data.uf.toLowerCase() })
            data.bairro && setField({ neighborhood: data.bairro })
            data.localidade && setField({ city: data.localidade })
            data.logradouro && setField({ street: data.logradouro })
          })
      }
    }
  }

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12}>
          <Form.Item label='Nome' name='name' rules={rules.name}>
            <Input
              placeholder='Informe um nome'
              size='large'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Sobrenome' name='surname' rules={rules.surname}>
            <Input
              placeholder='Informe um sobrenome'
              size='large'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='E-mail' name='email' rules={rules.email}>
            <Input
              placeholder='Informe um e-mail'
              size='large'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='CPF' name='cpf' rules={rules.cpf}>
            <CPFInput
              placeholder='Informe um CPF'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Telefone' name='phone' rules={rules.phone}>
            <PhoneInput
              placeholder='Informe um nº de telefone'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='CEP' name='zipcode' rules={rules.zipcode}>
            <Input
              placeholder='Informe um CEP'
              size='large'
              onChange={getCep}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Estado' name='state' rules={rules.state}>
            <Select size='large' block placeholder='Estado'>
              {states.map(e => <Option key={e.value} value={e.value}>{e.label}</Option>)}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Cidade' name='city' rules={rules.city}>
            <Input
              placeholder='Informe uma Cidade'
              size='large'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Bairro' name='neighborhood' rules={rules.neighborhood}>
            <Input
              placeholder='Informe um Bairro'
              size='large'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Rua' name='street' rules={rules.street}>
            <Input
              placeholder='Informe uma Rua'
              size='large'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Número' name='street_number' rules={rules.street_number}>
            <Input
              placeholder='Informe um Número'
              size='large'
              type='number'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Complemento' name='complementary' rules={rules.complementary}>
            <Input
              placeholder='Informe um Complemento'
              size='large'
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Data de expiração da conta' name='expiresIn' rules={rules.expiresIn}>
            <DatePicker />
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Status' name='active' rules={rules.active}>
            <Select size='large' block placeholder='Status do cadastro'>
              <Option value='true'>Ativo</Option>
              <Option value='false'>Bloqueado</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Possui renovação com desconto?' name='hasRenewalDiscount' rules={rules.hasRenewalDiscount}>
            <Select size='large' block placeholder='Selecione uma opção'>
              <Option value='true'>Sim</Option>
              <Option value='false'>Não</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Monitor' name='isMonitor' rules={rules.isMonitor}>
            <Select size='large' block placeholder='Selecione uma opção'>
              <Option value='true'>Sim</Option>
              <Option value='false'>Não</Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={12}>
          <Form.Item label='Restringir acesso a comunidade até' name='blockUserUntil'>
            <DatePicker format='DD/MM/YYYY HH:mm:ss' showTime />
          </Form.Item>
        </Col>

        {hasPasswordField && (
          <Col xs={24} sm={12}>
            <Form.Item label='Senha expirada?' name='passwordExpired' rules={rules.passwordExpired}>
              <Select size='large' block placeholder='Selecione uma opção'>
                <Option value='true'>Sim (O aluno terá que redefinir a senha)</Option>
                <Option value='false'>Não </Option>
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
    </>
  )
}

const rules = {
  name: [required],
  email: [required],
  surname: [required],
  cpf: [required],
  hasRenewalDiscount: [required],
  isMonitor: [required],
  passwordExpired: [required],
  phone: [],
  zipcode: [cep],
  state: [],
  city: [],
  neighborhood: [],
  street: [],
  street_number: [],
  complementary: [],
}

StudentForm.propTypes = {
  hasPasswordField: t.bool.isRequired,
  setField: t.func,
}

const states = [
  { label: 'Acre', value: 'ac' },
  { label: 'Alagoas', value: 'al' },
  { label: 'Amapá', value: 'ap' },
  { label: 'Amazonas', value: 'am' },
  { label: 'Bahia', value: 'ba' },
  { label: 'Ceará', value: 'ce' },
  { label: 'Distrito Federal', value: 'df' },
  { label: 'Espírito Santo', value: 'es' },
  { label: 'Goiás', value: 'go' },
  { label: 'Maranhão', value: 'ma' },
  { label: 'Mato Grosso', value: 'mt' },
  { label: 'Mato Grosso do Sul', value: 'ms' },
  { label: 'Minas Gerais', value: 'mg' },
  { label: 'Pará', value: 'pa' },
  { label: 'Paraíba', value: 'pn' },
  { label: 'Paraná', value: 'pr' },
  { label: 'Pernambuco', value: 'pe' },
  { label: 'Piauí', value: 'pi' },
  { label: 'Rio de Janeiro', value: 'rj' },
  { label: 'Rio Grande do Norte', value: 'rn' },
  { label: 'Rio Grande do Sul', value: 'rs' },
  { label: 'Rondônia', value: 'ro' },
  { label: 'Roraima', value: 'rr' },
  { label: 'Santa Catarina', value: 'sc' },
  { label: 'São Paulo', value: 'sp' },
  { label: 'Sergipe', value: 'se' },
  { label: 'Tocantins', value: 'to' },
]
