import { gql, useQuery } from '@apollo/client'
import { useCallback } from 'react'
import { getNodes } from 'resources'

const useKnowledgeAreasFetcher = (onFetchCompleted) => {
  const onCompleted = useCallback(
    (values) => {
      onFetchCompleted && onFetchCompleted(values.knowledgeAreas.nodes)
    },
    [onFetchCompleted]
  )

  const { data, loading } = useQuery(KNOWLEDGE_AREA_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted,
  })

  return {
    knowledgeAreas: getNodes('knowledgeAreas', data),
    loading,
  }
}

const KNOWLEDGE_AREA_QUERY = gql`
  {
    knowledgeAreas {
      nodes {
        id
        name
        slug
      }
    }
  }
`

export { useKnowledgeAreasFetcher }
