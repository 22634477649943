import { pipe, prop, path, curry } from 'ramda'

export const getColors = path(['theme', 'colors'])

export const getColor = curry(
  (color, props) => pipe(
    getColors,
    prop(color)
  )(props)
)

export const getColorVariant = curry(
  (colorName, variant, props) => pipe(
    getColors,
    path([colorName, variant])
  )(props)
)

export const getPrimaryVariant = getColorVariant('primary')
export const getSecondaryVariant = getColorVariant('secondary')
export const getDangerVariant = getColorVariant('danger')

export const spacing = curry((size, props) => pipe(
  path(['theme', 'spacing']),
  prop(size),
  spacing => `${spacing}px`
)(props))

export const getSidebarProp = curry(
  (propName, props) =>
    path(['theme', 'sidebar', propName], props)
)

export const getHeaderProp = curry(
  (propName, props) =>
    path(['theme', 'header', propName], props)
)
