import React from 'react'
import t from 'prop-types'

import { MenuItem } from './menu-item'
import { SubMenu as StyledSubMenu, Label } from './components'

const SubMenu = ({ label, icon, items, ...props }) => (
  <StyledSubMenu
    key='submenu'
    title={<SubMenuTitle label={label} icon={icon} />}
    {...props}
  >
    {items.map(({ label, link }, index) => (
      <MenuItem
        link={link}
        label={label}
        key={link}
      />
    ))}
  </StyledSubMenu>
)

const SubMenuTitle = ({ label, icon: Icon }) => (
  <>
    <Icon className='subtitle-icon' />
    <Label>{label}</Label>
  </>
)

SubMenuTitle.propTypes = {
  label: t.string.isRequired,
  icon: t.any,
}

SubMenu.propTypes = {
  label: t.string.isRequired,
  icon: t.any,
  items: t.arrayOf(t.shape({
    label: t.string.isRequired,
    link: t.string.isRequired,
  }).isRequired
  ).isRequired,
}

export { SubMenu }
