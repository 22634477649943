import React from 'react'
import styled from 'styled-components'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { getPrimaryVariant, getDangerVariant } from 'resources'

import { ActionItem } from './action-item'

export const EditAction = props => (
  <ActionItem
    label='Editar'
    icon={EditIcon}
    {...props}
  />
)

export const DeleteAction = props => (
  <ActionItem
    label='Excluir'
    icon={DeleteIcon}
    {...props}
  />
)

const EditIcon = styled(EditFilled)`
  color: ${getPrimaryVariant('main')};
`

const DeleteIcon = styled(DeleteFilled)`
  color: ${getDangerVariant('main')};
 `
