import { toEditRoute } from 'routes'
import { prop, curry } from 'ramda'

export const useRouter = () => {
  const goToEditPage = curry((route, key, record) => {
    const propName = key || 'id'
    const value = prop(propName, record)
    const editRoute = toEditRoute(route, value)
    window.open(editRoute, '_blank')
  })

  return {
    goToEditPage,
  }
}
