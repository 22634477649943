import React from 'react'
import styled from 'styled-components'
import { Select as AntdSelect, Empty } from 'antd'
import t from 'prop-types'

const { Option } = AntdSelect

/***
 * @type AntdSelect
 */
const Select = ({ children, notFoundContent, emptyText, ...antdProps }) => (
  <StyledSelect
    {...antdProps}
    maxTagCount={0}
    maxTagPlaceholder={renderLabel}
    showArrow
    notFoundContent={notFoundContent || <NotFound description={emptyText} />}
  >
    {children}
  </StyledSelect>
)

const NotFound = ({ description = 'Nenhum dado encontrado' }) => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={
      <>
        <span>{description}</span>
      </>
    }
  />
)

const renderLabel = ({ length }) =>
  length === 1 ? '1 item selecionado' : `${length} itens selecionados`

const StyledSelect = styled(AntdSelect)`
  .ant-select-multiple .ant-select-selection-item {
    border: none;
    background: transparent;
  }
  .ant-select-clear {
    font-size: 20px;
    color: #f5222d;
    top: 39%;
    right: 14px;
  }
`

NotFound.propTypes = {
  description: t.string,
}

Select.propTypes = {
  children: t.node,
  emptyText: t.string,
  notFoundContent: t.node,
}

Select.NotFound = NotFound

export { Select, Option }
