import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

import { usePdfsFetcher } from './use-pdfs-fetcher'

const PdfsSelect = ({
  loading,
  disciplineId,
  pdfType,
  valueName = 'id',
  ...antdProps
}) => {
  const { pdfs } = usePdfsFetcher({ disciplineId, pdfType })
  return (
    <Select
      {...antdProps}
      data-testid='pdfs-select'
      emptyText='Nenhum pdf encontrado'
      loading={loading}
    >
      {pdfs.map(({ title, ...subject }) => (
        <Option value={subject[valueName]} key={subject[valueName]}>{title}</Option>
      ))}
    </Select>
  )
}

PdfsSelect.propTypes = {
  loading: t.bool,
  valueName: t.oneOf(['id', 'slug']),
  disciplineId: t.string,
  pdfType: t.string,
}

export { PdfsSelect }
