import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { httpLink } from './http-link'
import { authLink } from './authentication-link'
import { validateAuthenticationError } from './refresh-token-link'
import { apolloSentry } from './apollo-sentry'

const client = new ApolloClient({
  link: ApolloLink.from([
    apolloSentry,
    validateAuthenticationError,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
})

export default client
