import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

export const openConfirmDialog = ({
  title = 'Aviso!',
  content = 'Deseja executar essa transação?',
  onConfirm,
  onCancel,
}) => {
  confirm({
    title,
    content,
    icon: <ExclamationCircleOutlined />,
    onOk: () => onConfirm(),
    okText: 'Ok',
    okType: 'success',
    onCancel: onCancel,
    // cancelButtonProps: { style: { display: 'none' } },
    cancelText: 'Cancelar',
  })
}

export const openConfirmDialogStudyPlan = ({
  title = 'Aviso!',
  content = 'Deseja executar essa transação?',
  onConfirm,
  onCancel,
}) => {
  confirm({
    title,
    content,
    icon: <ExclamationCircleOutlined />,
    onOk: () => onConfirm(),
    okText: 'Salvar',
    okType: 'success',
    onCancel: onCancel,
    cancelText: 'Cancelar',
  })
}
