import styled from 'styled-components'
import { getPrimaryVariant } from 'resources'
import { Form, Typography } from 'antd'

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;
  background: #E5ECFE;
  padding: 1.5rem 2rem;
  max-height: 20rem;
  overflow: auto;
`

const Title = styled(Typography.Title)`
  && {
    color: ${getPrimaryVariant('main')};
    margin-bottom: 1.5rem;
  }
`

const QuestionItem = styled.div`
  display: flex;
`

const Position = styled(Typography.Text)`
 && {
    color: ${getPrimaryVariant('main')};
    font-size: 1.25rem;
    font-weight: 600;
    margin-right: 1rem;
    width: 2rem;
    text-align: right;
  }
`

const FormItem = styled(Form.Item)`
  width: 100%;
`

export { Wrapper, QuestionItem, Title, Position, FormItem }
