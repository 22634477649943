import React from 'react'
import styled from 'styled-components'
import t from 'prop-types'
import { Typography } from 'antd'
import { media, spacing } from 'resources'

const PageHeader = ({ title, children }) => (
  <Header>
    <PageTitle level={3}>
      {title}
    </PageTitle>
    {children}
  </Header>
)

const Header = styled.div`
  padding: ${spacing(2)} ${spacing(7)} ${spacing(2)};

  ${media.lessThan('smallDesktop')`
    padding: ${spacing(2)} ${spacing(4)};
  `}
`

const PageTitle = styled(Typography.Title)`
  && {
    margin-bottom: ${spacing(5)};

    ${media.lessThan('tablet')`
      margin-bottom: ${spacing(4)};
    `}
  }
`

PageHeader.propTypes = {
  title: t.string.isRequired,
  children: t.node,
}

export { PageHeader }
