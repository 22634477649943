import React from 'react'
import { DatePicker as AntdDatePicker } from 'antd'
import locale from 'antd/es/date-picker/locale/pt_BR'
import 'moment/locale/pt-br'
const { RangePicker } = AntdDatePicker

export const DatePicker = props => (
  <AntdDatePicker
    style={{ width: '100%' }}
    locale={locale}
    format='DD/MM/YYYY'
    size='large'
    {...props}
  />
)

export const DateRange = props => (
  <RangePicker
    style={{ width: '100%' }}
    locale={locale}
    format='DD/MM/YYYY'
    size='large'
    {...props}
  />
)
