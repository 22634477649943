import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

import { useTeachersFetcher } from './use-teachers-fetcher'

const TeachersSelect = ({ loading, ...antdProps }) => {
  const { teachers } = useTeachersFetcher()

  return (
    <Select
      {...antdProps}
      loading={loading}
      emptyText='Nenhum professor encontrado'
    >
      {teachers.map(({ id, name }) => (
        <Option value={id} key={id}>{name}</Option>
      ))}
    </Select>
  )
}

TeachersSelect.propTypes = {
  loading: t.bool,
}

export { TeachersSelect }
