import React from 'react'
import t from 'prop-types'
import { Select, Option } from 'ui'

const useClassClassificationSelect = () => {
  const classification = [0, 1, 2, 3]

  return {
    classification,
  }
}

const ClassClassificationSelect = ({
  loading = false,
  valueName = 'id',
  onFetchCompleted,
  getValue,
  onSelect,
  ...antdProps
}) => {
  const { classification } = useClassClassificationSelect(
    onFetchCompleted
  )

  return (
    <Select
      {...antdProps}
      render='true'
      defaultActiveFirstOption
      emptyText='Nenhuma relevancia encontrada'
      onSelect={onSelect}
      allowClear
    >
      {classification.map((classification) => (
        <Option value={classification} key={classification[valueName]}>
          {classification}
        </Option>
      ))}
    </Select>
  )
}

ClassClassificationSelect.propTypes = {
  loading: t.bool,
  valueName: t.string,
  getValue: t.func,
  onFetchCompleted: t.func,
  onSelect: t.func,
}

export { ClassClassificationSelect }
