import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

export const openRefundDialog = ({
  title = 'Estornar item',
  content = 'Tem certeza que deseja estornar este item?',
  icon: Icon,
  onConfirm,
  onCancel,
}) => confirm({
  title,
  content,
  icon: Icon ? <Icon /> : <ExclamationCircleOutlined />,
  onOk: () => onConfirm(),
  onCancel,
  okText: 'Estornar',
  okType: 'danger',
  cancelText: 'Cancelar',
})
