import React, { useEffect } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import StudyPlanItem from './item'
import Title from './title'
import { AddItem, ListContainer, Wrapper, DropZone, ScrollContainer, ButtonWrapper } from '../components'
import t from 'prop-types'

import { preventDefault } from 'resources/composable'
import { pipe } from 'ramda'
import { PlusOutlined } from '@ant-design/icons'
import { EmptyWeek } from '../empty-week'

const InnerStudyPlanList = (props) => {
  const { resources } = props.studyPlans.items

  return resources ? resources.map((studyPlan, index) => {
    return (
      <>
        <Draggable key={studyPlan.item?.id || studyPlan.item.slug} draggableId={`${studyPlan.item?.id}-${index}` || `${studyPlan.item?.slug}-${index}`} index={index}>
          {(dragProvided, dragSnapshot) => (
            <StudyPlanItem
              key={studyPlan.id}
              studyPlan={studyPlan}
              isDragging={dragSnapshot.isDragging}
              isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
              provided={dragProvided}
              order={index}
              onRemove={props.onRemove}
            />
          )}
        </Draggable>
      </>
    )
  }) : null
}

const InnerStudyPlanList2 = (props) => {
  useEffect(() => {
    props.setFlag(false)
  }, [props])

  const { resources } = props.studyPlans.items

  return resources.map((studyPlan, index) => (
    <>
      <Draggable key={studyPlan.item?.id || studyPlan.item.slug} draggableId={studyPlan.item?.id || studyPlan.item.slug} index={index}>
        {(dragProvided, dragSnapshot) => (
          <StudyPlanItem
            key={studyPlan.id}
            studyPlan={studyPlan}
            isDragging={dragSnapshot.isDragging}
            isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
            provided={dragProvided}
            order={index}
            onRemove={props.onRemove}
          />

        )}
      </Draggable>

    </>
  ))
}

const InnerList = (props) => {
  const {
    studyPlans,
    dropProvided,
    id,
    onAdd,
    flag,
    setFlag,
    title,
    onRemove,
  } = props

  return (
    <>
      <ListContainer>
        <Title>{title}</Title>
        <DropZone ref={dropProvided.innerRef}>
          {studyPlans.items.resources.length === 0 && <EmptyWeek />}
          {!flag ? (
            <InnerStudyPlanList
              key={id}
              studyPlans={studyPlans}
              id={id}
              onRemove={onRemove}
            />
          ) : (
            <InnerStudyPlanList2
              key={id}
              studyPlans={studyPlans}
              id={id}
              setFlag={setFlag}
              onRemove={onRemove}
            />
          )}
          {dropProvided.placeholder}
        </DropZone>
      </ListContainer>

      <ButtonWrapper>
        <AddItem onClick={pipe(preventDefault, onAdd)}>
          <PlusOutlined />
          Adicionar item
        </AddItem>
      </ButtonWrapper>
    </>
  )
}

export default function StudyPlanList (props) {
  const {
    internalScroll,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    studyPlans,
    title,
    useClone,
    id,
    onAdd,
    flag,
    setFlag,
    onRemove,
  } = props

  return (
    <Droppable
      droppableId={listId}
      type={listType}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
            <StudyPlanItem
              studyPlan={studyPlans[descriptor.source.index]}
              provided={provided}
              isDragging={snapshot.isDragging}
              isClone
            />
          )
          : null
      }
    >
      {(dropProvided, dropSnapshot) => (
        <Wrapper
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <ScrollContainer>
              <InnerList
                key={id}
                studyPlans={studyPlans}
                title={title}
                dropProvided={dropProvided}
                id={id}
                onAdd={onAdd}
                flag={flag}
                setFlag={setFlag}
                onRemove={onRemove}
              />
            </ScrollContainer>
          ) : (
            <InnerList
              key={id}
              studyPlans={studyPlans}
              title={title}
              dropProvided={dropProvided}
              id={id}
              onAdd={onAdd}
              onRemove={onRemove}
            />
          )}
        </Wrapper>
      )}
    </Droppable>
  )
}

InnerList.propTypes = {
  id: t.number.isRequired,
  onAdd: t.func.isRequired,
  onRemove: t.func.isRequired,
  flag: t.bool,
  setFlag: t.func.isRequired,
  title: t.string,
  studyPlans: t.object,
  dropProvided: t.object,
}

StudyPlanList.propTypes = {
  id: t.number.isRequired,
  onAdd: t.func.isRequired,
  onRemove: t.func.isRequired,
  flag: t.bool,
  setFlag: t.func.isRequired,
  title: t.string,
  studyPlans: t.object,
  dropProvided: t.object,
  internalScroll: t.bool,
  isCombineEnabled: t.bool,
  listId: t.string,
  listType: t.string,
  useClone: t.bool,
}
